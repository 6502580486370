import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const AddressStep = ({
  formData,
  setFormData,
  onNext,
  addressFound,
  setAddressFound,
  addressObject,
  setAddressObject,
  lead
}) => {
  const [error, setError] = useState("");
  useEffect(() => {
    if (!_.isEmpty(lead)) {
      if (!addressFound) {
        setAddressObject({
          straat: lead.straat,
          plaats: lead.plaats,
          provincie: lead.provincie,
          woonfunctie: lead.woonfunctie,
          lat: lead.lat,
          lng: lead.lng,
          oppervlakte: lead.oppervlakte,
          bouwjaar: lead.bouwjaar,
          huisnummer: lead.huisnummer,
          toevoeging: lead.toevoeging,
          postcode: lead.postcode,
        });
        setFormData((prevData) => ({
          ...prevData,
          straat: lead.straat,
          plaats: lead.plaats,
          provincie: lead.provincie,
          woonfunctie: lead.woonfunctie,
          lat: lead.lat,
          lng: lead.lng,
          oppervlakte: lead.oppervlakte,
          bouwjaar: lead.bouwjaar,
          huisnummer: lead.huisnummer,
          toevoeging: lead.toevoeging,
          postcode: lead.postcode,
        }));
        setAddressFound(true);
        onNext();
      }
    }
  }, [lead]);

  const validateAddress = async () => {
    if (!formData.postcode || !formData.huisnummer) {
      setError("Vul alstublieft zowel postcode als huisnummer in.");
      return;
    }

    try {
      const url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${process.env.REACT_APP_PRO6BB
        }&nl_sixpp=${formData.postcode}&streetnumber=${formData.huisnummer
        }&extension=${formData.toevoeging ? formData.toevoeging : ""}`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        if (data && data.results && data.results.length > 0) {
          const result = data.results[0];
          // setFormData((prevData) => ({
          //   ...prevData,
          //   straat: result.street,
          //   plaats: result.city,
          //   provincie: result.province,
          //   woonfunctie: result.functions,
          //   lat: result.lat,
          //   lng: result.lng,
          //   oppervlakte: result.surface,
          //   bouwjaar: result.construction_year,
          // }));

          setAddressObject({
            straat: result.street,
            plaats: result.city,
            provincie: result.province,
            woonfunctie: result.functions,
            lat: result.lat,
            lng: result.lng,
            oppervlakte: result.surface,
            bouwjaar: result.construction_year,
            huisnummer: result.streetnumber,
            toevoeging: result.extension,
            postcode: result.nl_sixpp,
          });
          // setAddressFound(true);
          setError("");
          // onNext();
        } else {
          setError("Adres niet gevonden. Controleer de invoer.");
        }
      } else {
        setError("Adres niet gevonden. Controleer de invoer.");
      }
    } catch {
      setError("Er is een fout opgetreden bij het valideren van het adres.");
    }
  };

  const confirmAddress = () => {
    setFormData((prevData) => ({
      ...prevData,
      straat: addressObject.straat,
      plaats: addressObject.plaats,
      provincie: addressObject.provincie,
      woonfunctie: addressObject.woonfunctie,
      lat: addressObject.lat,
      lng: addressObject.lng,
      oppervlakte: addressObject.oppervlakte,
      bouwjaar: addressObject.bouwjaar,
      postcode: addressObject.postcode,
    }));
    setAddressFound(true);
    onNext();
  };

  return (
    <div>
      <h2>Stap 1: Adresgegevens</h2>
      <Row>
        <Col>
          <div className="inputs-address">
            <Row>
              <Col xs={6}>
                <label>Postcode *</label>
                <input
                  type="text"
                  value={formData.postcode}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      postcode: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col>
                <label>Huisnummer *</label>
                <input
                  type="text"
                  value={formData.huisnummer}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      huisnummer: e.target.value,
                    }))
                  }
                />
              </Col>
              <Col>
                <label>Toevoeging</label>
                <input
                  type="text"
                  value={formData.toevoeging || ""}
                  onChange={(e) =>
                    setFormData((prevData) => ({
                      ...prevData,
                      toevoeging: e.target.value,
                    }))
                  }
                />
              </Col>
            </Row>
          </div>
          {error && <p style={{ color: "red" }}>{error}</p>}
        </Col>

        {addressObject || addressFound ? (
          <Col>
            <div className="address-info">
              <span style={{ fontWeight: "bold" }}>
                Zijn deze gegevens correct?
              </span>
              <div className="info-line">
                {formData.straat ? formData.straat : addressObject.straat}{" "}
                {formData.huisnummer
                  ? formData.huisnummer
                  : addressObject.huisnummer}{" "}
                {formData.toevoeging
                  ? formData.toevoeging
                  : addressObject.toevoeging}
              </div>
              <div className="info-line">
                {formData.postcode ? formData.postcode : addressObject.postcode}{" "}
                {formData.plaats ? formData.plaats : addressObject.plaats}
              </div>
              <div className="info-line">
                {formData.province ? formData.province : addressObject.province}
              </div>
              <button
                style={{
                  marginTop: "10px",
                }}
                onClick={() => confirmAddress()}
              >
                Ja
              </button>
            </div>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <button onClick={validateAddress}>Zoek adres</button>
    </div>
  );
};

export default AddressStep;
