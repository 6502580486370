import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import BackButton from "../../components/BackButton";

const SingleProductPage = () => {
    const { id } = useParams();
    const [product, setProduct] = useState(null);
    const [dependencies, setDependencies] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch product details
    useEffect(() => {
        const fetchProduct = async () => {
            try {
                const productRef = doc(db, "productsNew", id);
                const productSnap = await getDoc(productRef);

                if (productSnap.exists()) {
                    const productData = productSnap.data();
                    setProduct(productData);

                    // Fetch dependencies if available
                    if (productData.dependencies?.length > 0) {
                        const dependencyDetails = await fetchDependencies(
                            productData.dependencies
                        );
                        setDependencies(dependencyDetails);
                    }
                } else {
                    console.error("No such product!");
                }
            } catch (error) {
                console.error("Error fetching product:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchProduct();
    }, [id]);

    // Helper function to fetch dependencies
    const fetchDependencies = async (dependencyIds) => {
        const results = [];
        for (const dependencyId of dependencyIds) {
            try {
                const dependencyRef = doc(db, "packages", dependencyId.packageId); // Adjust collection if needed
                const dependencySnap = await getDoc(dependencyRef);
                if (dependencySnap.exists()) {
                    results.push({ id: dependencyId, ...dependencySnap.data() });
                } else {
                    console.warn(`Dependency with ID ${dependencyId} not found.`);
                }
            } catch (error) {
                console.error(`Error fetching dependency with ID ${dependencyId}:`, error);
            }
        }
        return results;
    };

    if (loading) return <div>Laden...</div>;
    if (!product) return <div><BackButton /><br /><br />Product niet gevonden.</div>;
    console.log(product)
    return (
        <Container>
            <BackButton />
            <Row className="mt-4">
                <Col md={6}>
                    <Card>
                        <CardBody>
                            <h3>{product.name || "Naam onbekend"}</h3>
                            <p>
                                <strong>Categorie:</strong> {product.category || "Geen categorie"}
                            </p>
                            <p>
                                <strong>Type:</strong> {product.type || "Onbekend"}
                            </p>
                            <p>
                                <strong>Prijs:</strong> €{product.price?.toLocaleString() || "Onbekend"}
                            </p>
                            {product.priceNote && (
                                <p>
                                    <strong>Prijs Opmerking:</strong> {product.priceNote}
                                </p>
                            )}
                            {product.kWh > 0 && (
                                <p>
                                    <strong>kWh:</strong> {product.kWh}
                                </p>
                            )}
                            {product.specifications && (
                                <>
                                    <h5>
                                        <strong>Specificaties:</strong>
                                    </h5>
                                    <p
                                        dangerouslySetInnerHTML={{
                                            __html: product.specifications,
                                        }}
                                    ></p>
                                </>
                            )}
                            {product.highlights?.length > 0 && (
                                <>
                                    <h5>
                                        <strong>Hoogtepunten:</strong>
                                    </h5>
                                    <ul>
                                        {product.highlights.map((highlight, index) => (
                                            <li key={index}>{highlight}</li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {dependencies?.length > 0 && (
                                <>
                                    <h5>
                                        <strong>Afhankelijkheden:</strong>
                                    </h5>
                                    <ul>
                                        {dependencies.map((dep, index) => (
                                            <li key={index}>
                                                <strong>{dep.naam} pakket</strong>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            {product.pdfs?.length > 0 && (
                                <>
                                    <h5>
                                        <strong>PDF-bestanden:</strong>
                                    </h5>
                                    <ul>
                                        {product.pdfs.map((pdf, index) => (
                                            <li key={index}>
                                                <a href={pdf} target="_blank" rel="noopener noreferrer">
                                                    PDF {index + 1}
                                                </a>
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            )}
                            <Button
                                color="warning"
                                onClick={() => (window.location.href = `/admin/products/edit/${id}`)}
                            >
                                Bewerken
                            </Button>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={6}>
                    {product.images?.length > 0 ? (
                        <img
                            src={product.images[0]}
                            alt={product.name || "Product"}
                            style={{
                                width: "100%",
                                height: "400px",
                                objectFit: "contain",
                                backgroundColor: "#ffffff",
                                borderRadius: "8px",
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                width: "100%",
                                height: "400px",
                                backgroundColor: "#f0f0f0",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderRadius: "8px",
                            }}
                        >
                            Geen afbeelding beschikbaar
                        </div>
                    )}
                </Col>
                <Col xs={12}>
                    <Card className="mt-4">
                        <CardBody>
                            <h5>
                                <strong>Beschrijving:</strong>
                            </h5>
                            <p
                                dangerouslySetInnerHTML={{
                                    __html: product.description || "Geen beschrijving beschikbaar",
                                }}
                            ></p>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default SingleProductPage;
