import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { toast } from "react-toastify";
import Offerte1 from "../templates/offertes/Offerte1"; // Adjust the path
import axios from "axios";

const generatePasscode = (length = 8) => {
  const chars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let passcode = "";
  for (let i = 0; i < length; i++) {
    passcode += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return passcode;
};

const verifyPasscode = async (offer, inputPasscode) => {
  if (offer) {
    const data = offer;
    return data.passcode === inputPasscode; // Validate passcode
  } else {
    throw new Error("Data not found");
  }
};

const sendQuoteEmail = async (offerte) => {
  try {
    const quoteUrl = `${process.env.REACT_APP_WEB_URL}/public/offerte/${offerte.id}/${offerte.passcode}`;

    const email = {
      to: offerte.customerDetails.email,
      subject: "Uw persoonlijke offerte van Thuisbatterij Experts",
      placeholders: {
        name: `${offerte.customerDetails.firstName} ${offerte.customerDetails.lastName}`,
        quote_url: quoteUrl,
      },
      templateName: "quote",
    };

    const emailResponse = await sendEmailWithAttachment(email, offerte);
    return emailResponse;
  } catch (error) {
    console.error("Failed to send email:", error);
  }
};

const sendEmailWithAttachment = async (emailData, offerteData) => {
  try {
    const attachment = await generateOfferte(offerteData);
    const email = {
      ...emailData,
      attachment,
      attachmentName: `${offerteData.customerDetails.firstName} ${offerteData.customerDetails.lastName} - Offerte Thuisbatterij Experts.pdf`,
    };

    return await sendEmail(email);
    console.log("Email sent successfully!");
  } catch (error) {
    console.error("Error sending email:", error.message);
  }
};

const sendEmail = async (data) => {
  console.log(data);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/send-quote`,
      data
    );

    return response.data;
  } catch (error) {
    console.error(
      "Error sending email:",
      error.response?.data || error.message
    );
    return false;
    throw new Error("Failed to send email.");
  }
};

const generateOfferte = async (offerteData) => {
  console.log(offerteData);
  const pdfBlob = await Offerte1(offerteData);
  const pdfBase64 = await blobToBase64(pdfBlob);
  return pdfBase64;
};

const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]); // Remove the prefix
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const sendOfferteToClient = async (offerte) => {
  try {
    await sendQuoteEmail(offerte);
    console.log("Email sent successfully!");
  } catch (error) {
    console.log(`Error: ${error.message}`);
  }
};

const getNextOfferteNumber = async () => {
  const offerCounterRef = doc(db, "offerCounters", "offerCounter");

  try {
    // Fetch the current document
    const offerCounterSnap = await getDoc(offerCounterRef);

    if (!offerCounterSnap.exists()) {
      throw new Error("Offer counter document does not exist.");
    }

    // Get the current last offer number
    const currentNumber = offerCounterSnap.data().lastOfferNumber;

    // Increment the offer number
    const nextNumber = currentNumber + 1;

    // Update the database with the new number
    await updateDoc(offerCounterRef, { lastOfferNumber: nextNumber });

    return nextNumber;
  } catch (error) {
    console.error("Error fetching or updating offerte number: ", error);
    throw error; // Re-throw the error to handle it in the calling function
  }
};

const toastHelper = {
  success: (message) => {
    toast.success(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  error: (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
  info: (message) => {
    toast.info(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  },
};

export {
  generatePasscode,
  verifyPasscode,
  sendEmail,
  sendEmailWithAttachment,
  sendQuoteEmail,
  sendOfferteToClient,
  getNextOfferteNumber,
  toastHelper,
};
