import React, { useState, useEffect } from "react";
import { Form, useParams } from "react-router-dom";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import SignatureCanvas from "react-signature-canvas";
import {
  Button,
  Container,
  Row,
  Col,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  CardHeader,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { verifyPasscode } from "../../utils/helpers";
import "./CustomerOfferPage.css"; // Add a CSS file for custom styling
import LogoUrl from "../../Assets/logo.webp";
import Offerte1 from "../../templates/offertes/Offerte1";
import moment from "moment";
import "moment/min/locales";
moment.locale("nl");

const CustomerOfferPage = () => {
  const { offerId, passcode } = useParams();
  const [offer, setOffer] = useState(null);
  const [signature, setSignature] = useState(null);
  const [sigCanvas, setSigCanvas] = useState(null);
  const [agent, setAgent] = useState(null);
  const [cancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancellationReason, setCancellationReason] = useState("");
  const [cancellationDescription, setCancellationDescription] = useState("");

  const [signatureModalOpen, setSignatureModalOpen] = useState(false);

  const [pdfUrl, setPdfUrl] = useState(null);

  useEffect(() => {
    const fetchOffer = async () => {
      try {
        const offerRef = doc(db, "offertes", offerId);
        const offerSnap = await getDoc(offerRef);

        if (offerSnap.exists()) {
          const isValid = await verifyPasscode(offerSnap.data(), passcode);
          if (isValid) {
            setOffer(offerSnap.data());
            fetchAgentData(offerSnap.data().agent);
            generatePdf(offerSnap.data());
          }
        } else {
          console.error("Offer not found!");
        }
      } catch (error) {
        console.error("Error fetching offer:", error);
      }
    };

    fetchOffer();
  }, [offerId, passcode]);

  const fetchAgentData = async (agentId) => {
    if (!agentId) return;
    try {
      const agentRef = doc(db, "users", agentId); // Replace "agents" with your Firestore collection for agents
      const agentSnap = await getDoc(agentRef);

      if (agentSnap.exists()) {
        setAgent(agentSnap.data());
      } else {
        console.error("Agent not found!");
      }
    } catch (error) {
      console.error("Error fetching agent data:", error);
    }
  };

  const handleSaveSignature = async () => {
    if (!signature) return alert("Please sign before submitting.");

    try {
      const offerRef = doc(db, "offertes", offerId);

      const obj = {
        status: "accepted", // Ensure this matches the field names in Firestore
        signedAt: new Date(),
        signature: signature,
        updatedAt: new Date(),
      };
      console.log(obj);
      // Update specific fields
      await updateDoc(offerRef, obj);

      alert("Offer signed successfully!");
      window.location.reload();
    } catch (error) {
      console.error("Error saving signature:", error);
      alert("Failed to save signature. Please try again.");
    }
  };

  const handleClearSignature = () => {
    sigCanvas.clear();
    setSignature(null);
  };

  const handleCancelOffer = async () => {
    if (!cancellationReason)
      return alert("Please select a reason for cancellation.");

    try {
      const offerRef = doc(db, "offertes", offerId);

      const obj = {
        status: "cancelled",
        cancelledAt: new Date(),
        cancellationReason: cancellationReason,
        cancellationDescription: cancellationDescription,
        updatedAt: new Date(),
      };

      await updateDoc(offerRef, obj);

      setCancelModalOpen(false);
      alert("Offer cancelled successfully!");
    } catch (error) {
      console.error("Error cancelling offer:", error);
      alert("Failed to cancel offer. Please try again.");
    }
  };
  const generatePdf = async (offerte) => {
    // Convert PDF to blob
    const pdfBlob = await Offerte1(offerte);
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfUrl(pdfUrl);

    // Open the modal
  };

  const toggleSignatureModal = () => {
    setSignatureModalOpen(!signatureModalOpen);
  };

  const toggleCancelModal = () => {
    setCancelModalOpen(!cancelModalOpen);
  };

  if (!offer) return <div>Loading...</div>;

  const downloadOffertePdf = async (offerte) => {
    try {
      // Generate PDF blob
      const pdfBlob = await Offerte1(offerte);

      // Create a temporary URL for the blob
      const pdfUrl = URL.createObjectURL(pdfBlob);

      // Create an anchor element to trigger the download
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.download = `Offerte-${offerte.customerDetails?.firstName || "unknown"
        }.pdf`;
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(pdfUrl);
    } catch (error) {
      console.error("Error generating or downloading PDF:", error);
    }
  };

  const getStatusMessage = (status) => {
    // console.log(status);
    if (status === "accepted") {
      return (
        <>
          Geaccepteerd op:
          <br />
          {moment(offer.signedAt.toDate()).format("LL")}
        </>
      );
    } else if (status === "cancelled") {
      return (
        <>
          Geannuleerd op:
          <br />
          {moment(offer.cancelledAt.toDate()).format("LL")}
          <br />
          {offer.cancellationReason}
          <br />
          {offer.cancellationDescription}
        </>
      );
    } else {
      return status;
    }

    return status;
  };

  const showSignButtons = () => {
    if (offer.status === "cancelled") {
      return (
        <Col>
          Offerte is geannuleerd. <br />
          Neem contact op met uw adviseur
        </Col>
      );
    }

    if (offer.status === "accepted") {
      return;
    }

    return (
      <Col>
        <Button
          color="success"
          onClick={toggleSignatureModal}
          style={{ marginRight: 10 }}
        >
          Accepteer voorstel
        </Button>
        <Button onClick={toggleCancelModal}>Weiger voorstel</Button>
      </Col>
    );
  };
  return (
    <Container fluid className="customer-offer-page">
      <Row>
        {/* Sidebar */}
        <Col md="3" className="sidebar">
          <div className="branding">
            <img src={LogoUrl} alt="Company Logo" className="logo" />
          </div>
          <Card>
            <CardHeader className={`${offer.status === "accepted" ? "" : "dark"}`}>
              {offer.status === "accepted" ? <h5>Voorstel geaccepteerd</h5> : <h5>Bevestig uw voorstel</h5>}

            </CardHeader>
            <CardBody>
              <p>
                <b>Beste {offer.customerDetails?.firstName}</b>,<br />
                <br />
                Hartelijk dank voor uw aanvraag. Hier vindt u een overzicht van
                de gemaakte afspraken, uw persoonlijke gegevens en alle
                belangrijke informatie.
              </p>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h5>
                <strong>Huidige status</strong>{" "}
              </h5>
            </CardHeader>
            <CardBody>
              <span>{getStatusMessage(offer.status)}</span>
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h5>
                <strong>Documenten</strong>
              </h5>
            </CardHeader>
            <CardBody>
              <div className="offer-details-list">
                <ul>
                  <li>
                    <a
                      href="#"
                      rel="noreferrer"
                      onClick={() => downloadOffertePdf(offer)}
                    >
                      Download uw offerte
                    </a>
                  </li>
                  {offer.selectedProduct?.datasheets?.map((doc, index) => (
                    <li key={index}>
                      <a href={doc} target="_blank" rel="noreferrer">
                        Datasheet {index + 1}
                      </a>
                    </li>
                  ))}
                  <li>
                    <a
                      href="/path/to/voorwaarden.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Algemene voorwaarden
                    </a>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>

        {/* Offer Details */}
        <Col md="9" className="offer-details">
          <div className="header-row">
            <Row>
              {showSignButtons()}

              <Col className="justify-content-right">
                <div className="adviser-info text-end">
                  <p>
                    <strong>Uw adviseur: {agent?.name} </strong>
                    <br />
                    Telefoonnummer: {agent?.phonenumber}
                  </p>
                </div>
              </Col>
            </Row>
          </div>

          <iframe
            src={pdfUrl} // Replace with the actual PDF URL
            title="Uw Offerte"
            className="pdf-iframe"
          ></iframe>
        </Col>
      </Row>
      {/* Signature Modal */}
      <Modal isOpen={signatureModalOpen} toggle={toggleSignatureModal}>
        <ModalHeader toggle={toggleSignatureModal}>Ondertekenen</ModalHeader>
        <ModalBody>
          <h5>Zet uw handtekening</h5>
          <SignatureCanvas
            penColor="black"
            canvasProps={{
              className: "fullscreen-sigCanvas",
            }}
            ref={(ref) => setSigCanvas(ref)}
            onEnd={() =>
              setSignature(sigCanvas.getTrimmedCanvas().toDataURL("image/png"))
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveSignature}>
            Accepteren
          </Button>
          <Button color="secondary" onClick={handleClearSignature}>
            Wis
          </Button>
          <Button color="danger" onClick={toggleSignatureModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={cancelModalOpen} toggle={toggleCancelModal}>
        <ModalHeader toggle={toggleCancelModal}>Weiger voorstel</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="reason">Reden van weigering</Label>
            <Input
              type="select"
              id="reason"
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            >
              <option value="">Selecteer een reden</option>
              <option value="prijs">Prijs te hoog</option>
              <option value="andere-aanbieding">Andere aanbieding</option>
              <option value="geen-interesse">Geen interesse meer</option>
              <option value="overig">Overig</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="description">Toelichting</Label>
            <Input
              type="textarea"
              id="description"
              value={cancellationDescription}
              onChange={(e) => setCancellationDescription(e.target.value)}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleCancelOffer}>
            Bevestigen
          </Button>
          <Button color="secondary" onClick={toggleCancelModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default CustomerOfferPage;
