import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  Form,
} from "reactstrap";
import { db } from "../firebaseConfig"; // Assuming Firebase is used
import { collection, getDocs } from "firebase/firestore";

const ExtraProductsStep = ({ step, selectedProduct, onProceed, selectedExtras }) => {
  const [loading, setLoading] = useState(true);
  const [groupedProducts, setGroupedProducts] = useState({
    required: [],
    optional: [],
    omvormers: [],
  });
  const [manualProduct, setManualProduct] = useState({
    name: "",
    description: "",
    price: "",
    type: "single", // Default type
  });

  // Fetch products for the package
  useEffect(() => {
    const fetchProductsForPackage = async () => {
      try {
        const productsCollection = collection(db, "productsNew");
        const productsSnapshot = await getDocs(productsCollection);

        const fetchedProducts = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          selected: false,
          quantity: 0, // Default for non-selected products
        }));

        const required = fetchedProducts.filter((product) =>
          product.dependencies?.some(
            (dependency) =>
              dependency.packageId === selectedProduct?.id &&
              dependency.type === "required"
          )
        );

        const optional = [
          ...fetchedProducts.filter((product) =>
            product.dependencies?.some(
              (dependency) =>
                dependency.packageId === selectedProduct?.id &&
                dependency.type !== "required"
            )
          ),
          ...groupedProducts.optional.filter((product) => product.isCustom), // Persist custom products
        ];

        const omvormers = fetchedProducts.filter(
          (product) =>
            product.category === "omvormers" &&
            product.dependencies?.some(
              (dependency) => dependency.packageId === selectedProduct?.id
            )
        );

        const mergeSelectedExtras = (group) =>
          group.map((product) => {
            const matchedExtra = selectedExtras.find((extra) => extra.id === product.id);
            return {
              ...product,
              selected: !!matchedExtra,
              quantity: matchedExtra?.quantity || 0,
            };
          });

        setGroupedProducts({
          required: mergeSelectedExtras(required),
          optional: mergeSelectedExtras(optional),
          omvormers: mergeSelectedExtras(omvormers),
        });
      } catch (error) {
        console.error("Error fetching extra products:", error);
      } finally {
        setLoading(false);
      }
    };

    if (selectedProduct) {
      fetchProductsForPackage();
    }
  }, [selectedProduct, selectedExtras]);

  // Utility function to update a product by ID
  const updateProductById = useCallback(
    (id, updater) => {
      setGroupedProducts((prev) => {
        const updateGroup = (group) =>
          group.map((product) =>
            product.id === id ? { ...product, ...updater(product) } : product
          );

        return {
          required: updateGroup(prev.required),
          optional: updateGroup(prev.optional),
          omvormers: updateGroup(prev.omvormers),
        };
      });
    },
    [setGroupedProducts]
  );

  const toggleSelect = (id) => {
    updateProductById(id, (product) => ({
      selected: !product.selected,
    }));
  };

  const updateQuantity = (id, increment) => {
    updateProductById(id, (product) => ({
      quantity: Math.max((product.quantity || 0) + increment, 0),
    }));
  };

  const calculateTotal = useCallback(() => {
    const allSelectedExtras = [
      ...groupedProducts.required,
      ...groupedProducts.optional,
      ...groupedProducts.omvormers,
    ].filter((product) => product.selected || product.quantity > 0);

    const selectedProductPrice = Number(selectedProduct?.prijs) || 0;

    return allSelectedExtras.reduce((total, product) => {
      const extraCost =
        product.type === "quantity"
          ? Number(product.price) * Number(product.quantity)
          : Number(product.price);
      return total + extraCost;
    }, selectedProductPrice);
  }, [groupedProducts, selectedProduct]);

  const handleProceed = () => {
    const allExtras = [
      ...groupedProducts.required,
      ...groupedProducts.optional,
      ...groupedProducts.omvormers,
    ];

    const selectedExtras = allExtras.filter(
      (product) => product.selected || product.quantity > 0
    );

    onProceed(selectedExtras);
  };

  const handleAddManualProduct = () => {
    if (manualProduct.name && manualProduct.price) {
      setGroupedProducts((prev) => ({
        ...prev,
        optional: [
          ...prev.optional,
          {
            id: `custom-${Date.now()}`, // Unique ID for the custom product
            name: manualProduct.name,
            description: manualProduct.description,
            price: parseFloat(manualProduct.price),
            type: manualProduct.type,
            isCustom: true,
            selected: true, // Default to selected
            quantity: manualProduct.type === "quantity" ? 1 : 0,
          },
        ],
      }));

      setManualProduct({
        name: "",
        description: "",
        price: "",
        type: "single",
      });
    } else {
      alert("Please fill in both name and price fields.");
    }
  };

  const renderProductList = useCallback(
    (title, products) => {
      if (!products.length) return null;

      return (
        <>
          <h5 className={`mt-4 group-title ${title}`}>{title}</h5>
          {products.map((product) => (
            <Row key={product.id} className="mb-3 align-items-center product-line">
              <Col md={6}>
                <h5>{product.name}</h5>
              </Col>
              <Col md={3}>
                <p>€{product.price?.toLocaleString() || "N/A"}</p>
              </Col>
              <Col md={3} className="text-end">
                {product.type === "single" ? (
                  <FormGroup check>
                    <Label check>
                      <Input
                        type="checkbox"
                        checked={product.selected}
                        onChange={() => toggleSelect(product.id)}
                      />
                    </Label>
                  </FormGroup>
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    <Button
                      color="secondary"
                      size="sm"
                      onClick={() => updateQuantity(product.id, -1)}
                    >
                      -
                    </Button>
                    <span className="input-quantity">{product.quantity}</span>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => updateQuantity(product.id, 1)}
                    >
                      +
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          ))}
        </>
      );
    },
    [toggleSelect, updateQuantity]
  );

  if (loading) return <div>Loading extra products...</div>;

  return (
    <div className="container">
      <Row>
        <Col className="app-container">
          <h2>Extra producten</h2>
          {renderProductList("Verplicht", groupedProducts.required)}
          {renderProductList("Omvormers", groupedProducts.omvormers)}
          {renderProductList("Optioneel", groupedProducts.optional)}

          <Form className="mt-4">
            <h5 className={`mt-4 group-title`}>Handmatig product toevoegen</h5>
            <Row>
              <Col md={8}>
                <FormGroup>
                  <Label for="manualName">Titel</Label>
                  <Input
                    type="text"
                    id="manualName"
                    placeholder="Titel"
                    value={manualProduct.name}
                    onChange={(e) =>
                      setManualProduct((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={2}>
                <FormGroup>
                  <Label for="manualPrice">Prijs</Label>
                  <Input
                    type="number"
                    id="manualPrice"
                    placeholder="Prijs"
                    value={manualProduct.price}
                    onChange={(e) =>
                      setManualProduct((prev) => ({
                        ...prev,
                        price: e.target.value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>

              <Col md={12}>
                <FormGroup>
                  <Label for="manualDescription">Beschrijving</Label>
                  <Input
                    type="text"
                    id="manualDescription"
                    placeholder="Beschrijving"
                    value={manualProduct.description}
                    onChange={(e) =>
                      setManualProduct((prev) => ({
                        ...prev,
                        description: e.target.value,
                      }))
                    }
                  />
                </FormGroup>
              </Col>
              <Col md={12} className="text-end">
                <Button color="success" onClick={handleAddManualProduct}>
                  Toevoegen
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>

        <Col xs="4" className="overview-container">
          <Card className="p-4 shadow-sm">
            <CardBody>
              <div className="product-overview">
                {selectedProduct?.images?.[0] && (
                  <img
                    className="overview-image"
                    src={selectedProduct.images[0]}
                    alt={selectedProduct.naam}
                    style={{
                      width: "50%",
                      height: "auto",
                      marginBottom: "1rem",
                      margin: "0 auto",
                      borderRadius: "4px",
                    }}
                  />
                )}
                <h5>Overzicht</h5>
                <p>
                  <strong>{selectedProduct?.naam}</strong>
                </p>
                <p>
                  <strong>Prijs:</strong> €
                  {selectedProduct?.prijs?.toLocaleString() || "N/A"}
                </p>
                <p>
                  <strong>Uiteindelijke kosten:</strong> €
                  {calculateTotal().toLocaleString()}
                </p>
              </div>
            </CardBody>
          </Card>
          <div className="text-end mt-4">
            <Button color="success" block={true} onClick={handleProceed}>
              Volgende
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ExtraProductsStep;
