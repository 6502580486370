import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useUser } from "../context/UserContext";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";
import "./LeadsPage.css";
import moment from "moment/moment";
import TaskModal from "../components/AddTaskModal";
import { toastHelper } from "../utils/helpers";
import { useNavigate } from "react-router-dom";

const LeadsPage = () => {
  const { user } = useUser();
  const [leads, setLeads] = useState([]);
  const [agents, setAgents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [offerModalOpen, setOfferModalOpen] = useState(false);
  const [selectedLead, setSelectedLead] = useState(null);
  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const navigate = useNavigate()

  const [notes, setNotes] = useState([]); // To store fetched notes
  const [newNote, setNewNote] = useState({ title: "", description: "" });

  const [newLead, setNewLead] = useState({
    agent: null,
    selectedProduct: null,
    customerDetails: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      straat: "",
      plaats: "",
      provincie: "",
      woonfunctie: "",
      lat: "",
      lng: "",
      oppervlakte: "",
      bouwjaar: "",
      huisnummer: "",
      toevoeging: "",
      postcode: "",
    },
    selectedExtras: [],
    notes: [],
    appliedDiscounts: [],
    finalPrice: 0,
    passcode: null,
    priceDetails: {
      VAT: 0,
      basePrice: 0,
      discountedPrice: 0,
      totalIncludingVAT: 0,
    },
    status: "",
    signature: null,
    signedAt: null,
    sentAt: null,
    createdAt: null,
    updatedAt: null,
  });

  useEffect(() => {
    const fetchLeadsAndAgents = async () => {
      try {
        await fetchLeads();

        const agentsSnap = await getDocs(collection(db, "users"));
        const fetchedAgents = agentsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setAgents(fetchedAgents);
      } catch (error) {
        console.error("Error fetching leads or agents:", error);
      }
    };

    fetchLeadsAndAgents();
  }, []);

  const fetchLeads = async () => {
    const leadsSnap = await getDocs(collection(db, "leads"));
    const fetchedLeads = leadsSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setLeads(fetchedLeads);

  };
  const toggleModal = () => setModalOpen(!modalOpen);
  const toggleOfferModal = () => setOfferModalOpen(!offerModalOpen);
  const toggleTaskModal = () => setTaskModalOpen(!taskModalOpen);
  const toggleNoteModal = () => setNoteModalOpen(!noteModalOpen);

  const handleScheduleAppointment = async (task) => {
    try {
      await addDoc(collection(db, "tasks"), {
        ...task,
        leadId: selectedLead.id,
      });
      alert("Afspraak succesvol gepland.");
      toggleTaskModal();
    } catch (error) {
      console.error("Error scheduling appointment:", error);
      alert("Er is een fout opgetreden bij het plannen van de afspraak.");
    }
  };

  const handleAddressSearch = async () => {
    const { postcode, huisnummer, toevoeging } = newLead.customerDetails;
    if (!postcode || !huisnummer) return;

    try {
      const url = `https://api.pro6pp.nl/v1/autocomplete?auth_key=${process.env.REACT_APP_PRO6BB
        }&nl_sixpp=${postcode}&streetnumber=${huisnummer}&extension=${toevoeging ? toevoeging : ""
        }`;
      const response = await fetch(url);
      if (response.ok) {
        const data = await response.json();
        if (data && data.results && data.results.length > 0) {
          const result = data.results[0];
          setNewLead((prev) => ({
            ...prev,
            customerDetails: {
              ...prev.customerDetails,
              straat: result.street,
              plaats: result.city,
              provincie: result.province,
              woonfunctie: result.functions,
              lat: result.lat,
              lng: result.lng,
              oppervlakte: result.surface,
              bouwjaar: result.construction_year,
              huisnummer: result.streetnumber,
              toevoeging: result.extension,
              postcode: result.nl_sixpp,
            },
          }));
        }
      } else {
        alert("Geen adres gevonden voor deze postcode en huisnummer.");
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      alert("Fout bij het ophalen van adresgegevens.");
    }
  };

  const handleAddLead = async () => {
    const {
      customerDetails: {
        firstName,
        lastName,
        email,
        phone,
        straat,
        huisnummer,
        postcode,
        plaats,
      },
    } = newLead;

    if (
      !firstName ||
      !lastName ||
      !email ||
      !phone ||
      !straat ||
      !huisnummer ||
      !postcode ||
      !plaats
    ) {
      alert("Vul alle verplichte velden in.");
      return;
    }
    const newObj = {
      ...newLead,
      createdAt: new Date(),
      updatedAt: new Date(),
      status: "lead",
      agent: user.id,
      notes: notes
    }
    console.log(newObj)
    // return
    try {
      await addDoc(collection(db, "leads"), newObj);

      setNewLead({
        agent: user.id,
        selectedProduct: null,
        customerDetails: {
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          straat: "",
          plaats: "",
          provincie: "",
          woonfunctie: "",
          lat: "",
          lng: "",
          oppervlakte: "",
          bouwjaar: "",
          huisnummer: "",
          toevoeging: "",
          postcode: "",
        },
        selectedExtras: [],
        appliedDiscounts: [],
        finalPrice: 0,
        passcode: null,
        priceDetails: {
          VAT: 0,
          basePrice: 0,
          discountedPrice: 0,
          totalIncludingVAT: 0,
        },
        status: "",
        signature: null,
        signedAt: null,
        sentAt: null,
        createdAt: null,
        updatedAt: null,
        notes: []
      });
      toggleModal();
      fetchLeads();
      toastHelper.success("Lead is toegevoegd!");
    } catch (error) {
      toastHelper.success("Er gaat iets fout. Probeer het later nog eens!");

      console.error("Error adding lead:", error);
    }
  };

  const assignAgentToLead = async (leadId, agentId) => {
    if (!agentId) return;

    try {
      const leadRef = doc(db, "leads", leadId);
      await updateDoc(leadRef, { agent: agentId });

      // Update state to reflect the change
      setLeads((prevLeads) =>
        prevLeads.map((lead) =>
          lead.id === leadId ? { ...lead, agent: agentId } : lead
        )
      );

      alert("Agent succesvol toegewezen.");
    } catch (error) {
      console.error("Error assigning agent:", error);
      alert("Er is een fout opgetreden bij het toewijzen van de agent.");
    }
  };

  const handleCreateOffer = async () => {
    if (!selectedLead) return;
    selectedLead.status = "concept"
    try {
      navigate("/offerte", { state: { lead: selectedLead } });
    } catch (error) {
      console.error("Error creating offer:", error);
      alert("Fout bij het aanmaken van de offerte.");
    }
  };

  const openLeadInfo = (lead) => {
    setSelectedLead(lead)
    toggleOfferModal()
  }

  const handleAddNote = async () => {
    if (!newNote.title || !newNote.description) {
      toastHelper.error("Vul een titel en beschrijving in.");
      return;
    }

    try {
      const noteData = {
        ...newNote,
        createdAt: new Date(),
        userId: user?.id,
      };

      // await addDoc(collection(db, `leads/${selectedLead.id}/notes`), noteData);

      setNotes([...notes, noteData]);
      setNewNote({ title: "", description: "" });
      toastHelper.success("Notitie toegevoegd!");
      toggleNoteModal();
    } catch (error) {
      console.error("Error adding note:", error);
      toastHelper.error("Fout bij het toevoegen van de notitie.");
    }
  };

  const handleDeleteNote = async (index) => {

    try {
      setNotes((prevNotes) => prevNotes.filter((note, i) => i !== index));

    } catch (error) {
      console.error("Error deleting note:", error);
    }
  };

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>Leads Beheer</h3>
        <Button color="primary" onClick={toggleModal}>
          Nieuwe Lead
        </Button>
      </div>
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Datum</th>
                    <th>Naam</th>
                    <th>Email</th>
                    <th>Status</th>
                    {user.role === "admin" && <th>Agent</th>}
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {leads.map((lead) => (
                    <tr key={lead.id} >
                      <td>{moment(lead.createdAt.toDate()).format("lll")}</td>
                      <td>
                        {lead.customerDetails.firstName}{" "}
                        {lead.customerDetails.lastName}
                      </td>
                      <td>{lead.customerDetails.email}</td>
                      <td>{lead.status}</td>
                      {user.role === "admin" && (
                        <td>
                          {lead.agent ? (
                            agents.find((agent) => agent.id === lead.agent)
                              ?.personalInfo?.firstName || "Onbekend"
                          ) : (
                            <Input
                              type="select"
                              value=""
                              onChange={(e) =>
                                assignAgentToLead(lead.id, e.target.value)
                              }
                            >
                              <option value="">Selecteer een agent...</option>
                              {agents.map((agent) => (
                                <option key={agent.id} value={agent.id}>
                                  {agent.personalInfo?.firstName || "Onbekend"}
                                </option>
                              ))}
                            </Input>
                          )}
                        </td>
                      )}

                      <td>
                        <Button
                          color="success"
                          size="sm"
                          onClick={() => openLeadInfo(lead)}
                        >
                          Bekijk
                        </Button>

                        <TaskModal
                          isOpen={taskModalOpen}
                          toggle={toggleTaskModal}
                          onSave={handleScheduleAppointment}
                          agents={agents}
                          lead={lead}
                          initialData={{
                            title: "Afspraak plannen",
                            type: "fysieke afspraak",
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal className="custom-modal" isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Nieuwe Lead</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Voornaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.firstName}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      firstName: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label>Achternaam</Label>
              <Input
                type="text"
                value={newLead.customerDetails.lastName}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      lastName: e.target.value,
                    },
                  })
                }
              />
            </Col>
          </Row>
          <Label className="mt-2">Email</Label>
          <Input
            type="email"
            value={newLead.customerDetails.email}
            onChange={(e) =>
              setNewLead({
                ...newLead,
                customerDetails: {
                  ...newLead.customerDetails,
                  email: e.target.value,
                },
              })
            }
          />
          <Label className="mt-2">Telefoon</Label>
          <Input
            type="text"
            value={newLead.customerDetails.phone}
            onChange={(e) =>
              setNewLead({
                ...newLead,
                customerDetails: {
                  ...newLead.customerDetails,
                  phone: e.target.value,
                },
              })
            }
          />
          <Row>
            <Col>
              <Label className="mt-2">Postcode</Label>
              <Input
                type="text"
                value={newLead.customerDetails.postcode}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      postcode: e.target.value,
                    },
                  })
                }
              />
              <Button
                color="info"
                size="sm"
                className="mt-2"
                onClick={handleAddressSearch}
              >
                Adres Ophalen
              </Button>
            </Col>
            <Col>
              <Label className="mt-2">Huisnummer</Label>
              <Input
                type="text"
                value={newLead.customerDetails.huisnummer}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      huisnummer: e.target.value,
                    },
                  })
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Toevoeging</Label>
              <Input
                type="text"
                value={newLead.customerDetails.toevoeging}
                onChange={(e) =>
                  setNewLead({
                    ...newLead,
                    customerDetails: {
                      ...newLead.customerDetails,
                      toevoeging: e.target.value,
                    },
                  })
                }
              />
            </Col>
          </Row>

          <Row>
            <Col>
              <Label className="mt-2">Straat</Label>
              <Input
                type="text"
                value={newLead.customerDetails.straat}
                disabled
              />
            </Col>
            <Col>
              {" "}
              <Label className="mt-2">Plaats</Label>
              <Input
                type="text"
                value={newLead.customerDetails.plaats}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button color="info" onClick={toggleNoteModal}>
                Notitie Toevoegen
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="notes">
                <h3>Notities</h3>
                <ul>
                  <Row>
                    {notes.map((a, i) => {
                      return <Col key={i} xs={6}>
                        <div className="delete-button" onClick={() => handleDeleteNote(i)} >X</div>
                        <li>
                          <span>{a.title}</span>
                          {a.description}
                        </li>
                      </Col>
                    })}
                  </Row>

                </ul>
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddLead}>
            Lead Toevoegen
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={offerModalOpen} toggle={toggleOfferModal}>
        <ModalHeader toggle={toggleOfferModal}>Lead informatie</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12}><h3>Persoonlijke gegevens</h3></Col>
            <Col>
              <div className="customer-info">

                <div className="info-line">
                  <span>Naam:</span> {selectedLead?.customerDetails.firstName} {selectedLead?.customerDetails.lastName}<br />
                  <span>Telefoonnummer:</span> {selectedLead?.customerDetails.phone}<br />
                  <span>E-mailadres:</span> {selectedLead?.customerDetails.email}<br />
                </div>

              </div>
            </Col>
            <Col>
              <div className="customer-info">
                <div className="info-line">
                  <span>Adres:</span>
                  {selectedLead?.customerDetails.straat} {selectedLead?.customerDetails.huisnummer}<br />
                  {selectedLead?.customerDetails.postcode} {selectedLead?.customerDetails.plaats}<br />
                  {selectedLead?.customerDetails.provincie}<br />
                  <a target="_blank" href={`https://maps.google.com/?q=${selectedLead?.customerDetails.straat}+${selectedLead?.customerDetails.huisnummer}+${selectedLead?.customerDetails.postcode}+${selectedLead?.customerDetails.plaats}`}>Google maps</a>
                </div>
              </div>
            </Col>
          </Row>
          {selectedLead?.notes?.length > 0 && <Row>
            <Col>
              <div className="notes">
                <h3>Notities</h3>
                <ul>
                  <Row>
                    {selectedLead?.notes.map((a, i) => {
                      return <Col key={i} xs={6}>
                        <li>
                          <span>{a.title}</span>
                          {a.description}
                        </li>
                      </Col>
                    })}
                  </Row>

                </ul>
              </div>
            </Col>
          </Row>}

          {/* <p>
            Wilt u een offerte maken voor{" "}
            <strong>{selectedLead?.customerDetails.firstName} {selectedLead?.customerDetails.lastName}</strong>?
          </p> */}
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() => setTaskModalOpen(true)}
          >
            Plan Afspraak
          </Button>
          <Button color="primary" onClick={handleCreateOffer}>
            Offerte Aanmaken
          </Button>
          <Button color="secondary" onClick={toggleOfferModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal className="custom-modal" isOpen={noteModalOpen} toggle={toggleNoteModal}>
        <ModalHeader toggle={toggleNoteModal}>Nieuwe Notitie</ModalHeader>
        <ModalBody>
          <Label>Titel</Label>
          <Input
            type="text"
            value={newNote.title}
            onChange={(e) =>
              setNewNote({ ...newNote, title: e.target.value })
            }
          />
          <Label className="mt-2">Beschrijving</Label>
          <Input
            type="textarea"
            value={newNote.description}
            onChange={(e) =>
              setNewNote({ ...newNote, description: e.target.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddNote}>
            Notitie Toevoegen
          </Button>
          <Button color="secondary" onClick={toggleNoteModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </Container >
  );
};

export default LeadsPage;
