import React, { useState, useEffect } from "react";
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    CategoryScale,
    Legend,
    Tooltip,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilter } from "react-icons/fa";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

ChartJS.register(LineElement, PointElement, LinearScale, CategoryScale, Legend, Tooltip);


const GraphWidget = ({ title }) => {
    const [modalOpen, setModalOpen] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [data, setData] = useState({ labels: [], datasets: [] });

    const predefinedOptions = {
        "Laatste 7 dagen": [7],
        "Laatste 14 dagen": [14],
        "Laatste 30 dagen": [30],
        "Laatste 3 maanden": [90],
    };

    const toggleModal = () => setModalOpen(!modalOpen);

    const fetchData = async (from, to) => {
        try {
            const offertesRef = collection(db, "offertes");
            const q = query(
                offertesRef,
                where("createdAt", ">=", from),
                where("createdAt", "<=", to)
            );

            const querySnapshot = await getDocs(q);

            const labels = [];
            const sentCounts = [];
            const signedCounts = [];

            for (
                let d = new Date(from);
                d <= to;
                d.setDate(d.getDate() + 1)
            ) {
                labels.push(new Date(d).toLocaleDateString("nl-NL"));
                sentCounts.push(0);
                signedCounts.push(0);
            }

            querySnapshot.forEach((doc) => {
                const data = doc.data();

                const date = data.createdAt.toDate().toLocaleDateString("nl-NL");
                const status = data.status || "concept";

                const index = labels.indexOf(date);
                if (index !== -1) {
                    if (status === "Verzonden") {
                        sentCounts[index]++;
                    } else if (status === "accepted") {
                        signedCounts[index]++;
                        sentCounts[index]++;
                    }
                }
            });

            setData({
                labels,
                datasets: [
                    {
                        label: "Offertes Verzonden",
                        data: sentCounts,
                        borderColor: "#4e73df",
                        backgroundColor: "rgba(78, 115, 223, 0.1)",
                        borderWidth: 2,
                    },
                    {
                        label: "Netto Sales",
                        data: signedCounts,
                        borderColor: "#1cc88a",
                        backgroundColor: "rgba(28, 200, 138, 0.1)",
                        borderWidth: 2,
                    },
                ],

            });
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const applyPredefinedFilter = (days) => {
        const now = new Date();
        const from = new Date(now.setDate(now.getDate() - days));
        const to = new Date();

        setStartDate(from);
        setEndDate(to);
        fetchData(from, to);
        toggleModal();
    };

    const applyFilter = () => {
        if (startDate && endDate) {
            fetchData(startDate, endDate);
        }
        toggleModal();
    };

    useEffect(() => {
        const now = new Date();
        const from = new Date(now.setDate(now.getDate() - 30));
        const to = new Date();

        setStartDate(from);
        setEndDate(to);
        fetchData(from, to);
    }, []);

    const options = {
        legend: {
            labels: {
                fontColor: "blue",
                fontSize: 18
            }
        },
        scales: {
            yAxes: [{
                ticks: {
                    fontColor: "white",
                    fontSize: 18,
                    stepSize: 1,
                    beginAtZero: true
                }
            }],
            xAxes: [{
                ticks: {
                    fontColor: "white",
                    fontSize: 14,
                    stepSize: 1,
                    beginAtZero: true
                }
            }]
        }
    }
    return (
        <div className="widget graph-widget">
            <div className="widget-header">
                <h3>{title}</h3>
                <Button
                    className="filter-button"
                    color="secondary"
                    onClick={toggleModal}
                >
                    <FaFilter />
                </Button>
            </div>
            <div className="widget-content">
                <Line
                    data={data}
                    options={{
                        responsive: true,
                        plugins: {
                            legend: {
                                display: true,
                                position: "top",
                                labels: {
                                    font: {
                                        size: 16, // Larger font size for legend labels
                                        family: "'Roboto', 'Arial', sans-serif",
                                    },
                                    color: "#4e73df", // Nice color for labels
                                },
                            },
                        },
                        scales: {
                            x: {
                                type: "category",
                                title: {
                                    display: true,
                                    text: "Datum",
                                    font: {
                                        size: 21,
                                        weight: "bold",
                                    },
                                },
                                ticks: {
                                    font: {
                                        size: 16,
                                    },
                                    color: "#858796",
                                },
                            },
                            y: {
                                beginAtZero: true, // Always start from 0
                                title: {
                                    display: true,
                                    text: "Aantal Offertes",
                                    font: {
                                        size: 21,
                                        weight: "bold",
                                    },
                                },
                                ticks: {
                                    font: {
                                        size: 16,
                                    },
                                    color: "#858796",
                                },
                            },
                        },
                    }}
                />
            </div>
            <Modal isOpen={modalOpen} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>Filter Periode</ModalHeader>
                <ModalBody>
                    <div>
                        <h6>Vooraf ingestelde filters</h6>
                        {Object.entries(predefinedOptions).map(([label, [days]]) => (
                            <Button
                                key={label}
                                color="info"
                                className="me-2 mb-2"
                                onClick={() => applyPredefinedFilter(days)}
                            >
                                {label}
                            </Button>
                        ))}
                    </div>
                    <hr />
                    <div>
                        <h6>Aangepast bereik</h6>
                        <div>
                            <label>Startdatum:</label>
                            <DatePicker
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                selectsStart
                                startDate={startDate}
                                endDate={endDate}
                                placeholderText="Selecteer startdatum"
                            />
                        </div>
                        <div style={{ marginTop: "10px" }}>
                            <label>Einddatum:</label>
                            <DatePicker
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                selectsEnd
                                startDate={startDate}
                                endDate={endDate}
                                minDate={startDate}
                                placeholderText="Selecteer einddatum"
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={applyFilter}>
                        Toepassen
                    </Button>
                    <Button color="secondary" onClick={toggleModal}>
                        Annuleren
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default GraphWidget;
