import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import { useUser } from "../context/UserContext"; // Assume you have a context providing user info
import { Button, Card, CardBody, Container, Table } from "reactstrap";
import "./Offertes.css"; // Optional CSS for styling
import moment from "moment";

const OffertesPage = () => {
  const [offertes, setOffertes] = useState([]);
  const [filteredOffertes, setFilteredOffertes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useUser(); // Get current user info from context
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  // Popup states
  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);

  useEffect(() => {
    if (showFilterPopup) {
      setShowSortPopup(false);
    }
  }, [showFilterPopup]);

  useEffect(() => {
    if (showSortPopup) {
      setShowFilterPopup(false);
    }
  }, [showSortPopup]);
  useEffect(() => {
    const fetchOffertes = async () => {
      try {
        let q;
        const offertesRef = collection(db, "offertes");
        if (user?.role === "admin") {
          // Admin can see all data
          q = query(offertesRef, orderBy("createdAt", "desc"));
        } else {
          // Filter only user-related data
          q = query(
            offertesRef,
            where("agent", "==", user?.uid),
            orderBy("createdAt", "desc")
          );
        }

        const querySnapshot = await getDocs(q);
        const fetchedOffertes = querySnapshot.docs.map((doc) => {
          return ({
            ...doc.data(),
            id: doc.id,

          })
        });
        console.log(fetchedOffertes)
        setOffertes(fetchedOffertes);
        setFilteredOffertes(fetchedOffertes);
      } catch (err) {
        console.error("Error fetching offertes:", err);
        setError(
          "Er zijn geen offertes gevonden. Voeg nu je eerste offerte toe."
        );
      } finally {
        setLoading(false);
      }
    };

    fetchOffertes();
  }, []);

  // Filter logic
  useEffect(() => {
    let filtered = offertes;

    if (searchQuery) {
      filtered = filtered.filter((offerte) =>
        [
          offerte.customerDetails?.firstName,
          offerte.customerDetails?.lastName,
          offerte.customerDetails?.straat,
          offerte.customerDetails?.postcode,
        ]
          .filter(Boolean) // Remove null or undefined values
          .some((field) =>
            field.toLowerCase().includes(searchQuery.toLowerCase())
          )
      );

    }

    if (minPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice) >= parseFloat(minPrice)
      );
    }

    if (maxPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice) <= parseFloat(maxPrice)
      );
    }

    setFilteredOffertes(filtered);
  }, [searchQuery, minPrice, maxPrice, offertes]);

  const handleDuplicateOfferte = async (offerte) => {
    try {
      // Duplicate offerte data
      const newOfferteData = {
        ...offerte,
        customerDetails: {
          ...offerte.customerDetails,
          name: `${offerte.customerDetails?.firstName} (Kopie)`,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      delete newOfferteData.id; // Remove the original ID

      // Save the duplicated offerte
      const docRef = await addDoc(collection(db, "offertes"), newOfferteData);

      // Redirect to edit/add offerte page with the new offerte ID
      window.location.href = `/offertes/edit/${docRef.id}`;
    } catch (err) {
      console.error("Error duplicating offerte:", err);
      alert("Het dupliceren van de offerte is mislukt.");
    }
  };

  if (loading) return <div>Loading offertes...</div>;
  if (error)
    return (
      <Container>
        <Card>
          <CardBody className={"text-center"}>
            <div className="error mb-5">{error}</div>
            <Button
              style={{ marginLeft: "auto", marginRight: 20 }}
              color="primary"
              onClick={() => (window.location.href = "/offerte")}
            >
              Voeg Nieuwe Offerte Toe
            </Button>
          </CardBody>
        </Card>
      </Container>
    );

  //   console.log(filteredOffertes);
  return (
    <Container className="offertes-page">
      <div className="header-section">
        <h2>Offertes Overzicht</h2>
        <Button
          style={{ marginLeft: "auto", marginRight: 20 }}
          color="primary"
          onClick={() => (window.location.href = "/offerte")}
        >
          Voeg Nieuwe Offerte Toe
        </Button>
        {/* <div className="view-toggle">
                    <Button
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        color={isTableView ? "secondary" : "primary"}
                        onClick={() => setIsTableView(false)}
                    >
                        <FiGrid style={{ fontSize: 25 }} />
                    </Button>
                    <Button
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        color={isTableView ? "primary" : "secondary"}
                        onClick={() => setIsTableView(true)}
                    >
                        <FiAlignJustify style={{ fontSize: 25 }} />

                    </Button>
                </div> */}
      </div>

      {/* Filters Section */}

      <div className="filters">
        <input
          className="flex-2"
          type="text"
          placeholder="Zoek op naam of adres..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* <Button className="filter-button filter" onClick={() => setShowFilterPopup(!showFilterPopup)}>
                    <FaFilter />
                </Button>
                <Button className="filter-button sort" onClick={() => setShowSortPopup(!showSortPopup)}>
                    <FaSortAmountDown />
                </Button> */}

        {/* Filter Popup */}
        {showFilterPopup && (
          <div className="popup-overlay">
            <h3>Filters</h3>
            <div className="popup-content">
              <label>
                <span>Min. Prijs (€):</span>
                <input
                  type="number"
                  placeholder="Min. prijs"
                  value={minPrice}
                  onChange={(e) => setMinPrice(e.target.value)}
                />
              </label>
              <label>
                <span>Max. Prijs (€):</span>
                <input
                  type="number"
                  placeholder="Max. prijs"
                  value={maxPrice}
                  onChange={(e) => setMaxPrice(e.target.value)}
                />
              </label>
              <div className="popup-actions">
                <Button
                  color="secondary"
                  onClick={() => setShowFilterPopup(false)}
                >
                  Sluiten
                </Button>
                <Button color="primary" onClick={() => setShowSortPopup(false)}>
                  Toepassen
                </Button>
              </div>
            </div>
          </div>
        )}

        {/* Sort Popup */}
        {showSortPopup && (
          <div className="popup-overlay">
            <h3>Sorteren</h3>
            <div className="popup-content">
              <Button color="primary" onClick={() => setShowSortPopup(false)}>
                Sorteer op Datum
              </Button>
              <Button color="primary" onClick={() => setShowSortPopup(false)}>
                Sorteer op Prijs
              </Button>
              <div className="popup-actions">
                <Button
                  color="secondary"
                  onClick={() => setShowSortPopup(false)}
                >
                  Sluiten
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Toggle between Table and Card View */}

      <Table responsive striped>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Status</th>
            <th>#</th>
            <th>Offerte</th>
            <th width="200px">Datum aangemaakt</th>
            {/*                             
                            <th>Acties</th> */}
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          {filteredOffertes
            .sort((a, b) => {
              return (
                new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
              );
            })
            .map((offerte) => (
              <tr
                key={offerte.id}
                className="clickable-tr"
                onClick={() =>
                  (window.location.href = `/offertes/${offerte.id}`)
                }
              >
                <td valign="middle">{offerte.status || "Onbekend"}</td>
                <td valign="middle">{offerte.offertNr || "n.n.b."}</td>
                <td valign="middle">
                  <strong>
                    {offerte.customerDetails?.firstName} {offerte.customerDetails?.lastName}<br />
                  </strong>
                  {offerte.customerDetails?.straat} {offerte.customerDetails?.huisnummer}, {offerte.customerDetails?.plaats}
                  <br />
                  {offerte.selectedProduct?.naam || "Geen product"}
                </td>
                <td valign="middle">
                  {moment(offerte.createdAt.toDate()).format("DD-MM-YYYY") ||
                    "Onbekend"}
                </td>

                {/* <td>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => (window.location.href = `/offertes/${offerte.id}`)}
                                    >
                                        Bekijken
                                    </Button>{" "}
                                    <Button
                                        color="warning"
                                        size="sm"
                                        onClick={() => (window.location.href = `/offertes/edit/${offerte.id}`)}
                                    >
                                        Bewerken
                                    </Button>{" "}
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        onClick={() => handleDuplicateOfferte(offerte)}
                                    >
                                        Dupliceren
                                    </Button>
                                </td> */}
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default OffertesPage;
