import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useUser } from "../context/UserContext"; // Assume this provides the logged-in user's info
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Container,
  Card,
  CardBody,
} from "reactstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AgendaPage.css";

const localizer = momentLocalizer(moment);

const AgendaPage = () => {
  const { user } = useUser(); // Get the logged-in user
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: null,
    end: null,
    type: "Task",
  });
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Fetch tasks and meetings for the logged-in user
  useEffect(() => {
    const fetchTasksAndMeetings = async () => {
      try {
        const tasksQuery = query(
          collection(db, "tasks"),
          where("agent", "==", user?.uid) // Filter tasks assigned to the current user
        );
        const meetingsQuery = query(
          collection(db, "meetings"),
          where("attendees", "array-contains", user?.uid) // Filter meetings the user is part of
        );

        const tasksSnap = await getDocs(tasksQuery);
        const meetingsSnap = await getDocs(meetingsQuery);

        const tasks = await Promise.all(
          tasksSnap.docs.map(async (document) => {
            const taskData = document.data();
            let clientData = null;
            let offerteData = null;

            // Fetch related client or offerte
            if (taskData.clientId) {
              const clientDoc = await getDoc(
                doc(db, "clients", taskData.clientId)
              );
              clientData = clientDoc.exists() ? clientDoc.data() : null;
            }

            if (taskData.offerte) {
              const offerteDoc = await getDoc(
                doc(db, "offertes", taskData.offerte)
              );

              if (offerteDoc.exists()) {
                offerteData = offerteDoc.data();
                offerteData.id = offerteDoc.id;
              }
            }

            return {
              id: document.id,
              title: `${taskData.type}: ${taskData.title}`,
              start: taskData.startTime
                ? new Date(taskData.startTime.toDate())
                : null,
              end: taskData.endTime
                ? new Date(taskData.endTime.toDate())
                : null,
              type: "Task",
              description: taskData.description || "Geen beschrijving",
            };
          })
        );

        const meetings = meetingsSnap.docs.map((document) => {
          const meetingData = document.data();
          return {
            id: document.id,
            title: `Vergadering: ${meetingData.title}`,
            start: new Date(meetingData.startTime.toDate()),
            end: new Date(meetingData.endTime.toDate()),
            type: "Meeting",
            attendees: meetingData.attendees || [],
            description: meetingData.description || "Geen beschrijving",
          };
        });
        console.log("Tasks:", tasks); // Debug all tasks

        setEvents([...tasks, ...meetings]);
      } catch (error) {
        console.error("Error fetching events:", error);
      }
    };

    fetchTasksAndMeetings();
  }, [user]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleDetailModal = () => setDetailModalOpen(!detailModalOpen);

  const handleAddEvent = async () => {
    if (!newEvent.title || !newEvent.start) {
      alert("Titel en starttijd zijn verplicht.");
      return;
    }

    try {
      const eventToAdd = { ...newEvent };

      if (newEvent.type === "Task") {
        await addDoc(collection(db, "tasks"), {
          name: newEvent.title,
          startTime: newEvent.start, // Ensure this is properly set
          endTime: newEvent.end, // Optional
          agent: user?.uid,
          description: newEvent.description || "Geen beschrijving",
        });
      } else if (newEvent.type === "Meeting") {
        await addDoc(collection(db, "meetings"), {
          title: newEvent.title,
          startTime: newEvent.start, // Ensure this is properly set
          endTime: newEvent.end, // Required
          attendees: [user?.uid], // Include the current user
        });
      }

      setEvents([...events, { ...eventToAdd }]);
      setNewEvent({ title: "", start: null, end: null, type: "Task" });
      toggleModal();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
    toggleDetailModal();
  };

  console.log(events);
  return (
    <Container>
      <div className="agenda-page">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Agenda</h3>
          <Button color="primary" onClick={toggleModal}>
            Nieuw Evenement
          </Button>
        </div>
        <Card>
          <CardBody>
            <Calendar
              localizer={localizer}
              events={events.map((event) => ({
                ...event,
                end: event.end || event.start, // Fallback to start if end is missing
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              messages={{
                next: "Volgende",
                previous: "Vorige",
                today: "Vandaag",
                month: "Maand",
                week: "Week",
                day: "Dag",
                agenda: "Agenda",
              }}
              eventPropGetter={(event) => ({
                style: {
                  backgroundColor:
                    event.type === "Task" ? "#ff9800" : "#3f51b5",
                  color: "white",
                },
              })}
              onSelectEvent={handleSelectEvent}
            />
          </CardBody>
        </Card>

        {/* Add Event Modal */}
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Nieuw Evenement</ModalHeader>
          <ModalBody>
            <Label>Titel</Label>
            <Input
              type="text"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
              placeholder="Titel van evenement..."
            />
            <Label className="mt-2">Type</Label>
            <Input
              type="select"
              value={newEvent.type}
              onChange={(e) =>
                setNewEvent({ ...newEvent, type: e.target.value })
              }
            >
              <option value="Task">Taak</option>
              <option value="Meeting">Vergadering</option>
            </Input>
            <Label className="mt-2">Starttijd</Label>
            <DatePicker
              selected={newEvent.start}
              onChange={(date) => setNewEvent({ ...newEvent, start: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Starttijd selecteren"
              className="form-control"
            />
            <Label className="mt-2">Eindtijd</Label>
            <DatePicker
              selected={newEvent.end}
              onChange={(date) => setNewEvent({ ...newEvent, end: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Eindtijd selecteren"
              className="form-control"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddEvent}>
              Toevoegen
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>

        {/* Event Detail Modal */}
        <Modal isOpen={detailModalOpen} toggle={toggleDetailModal}>
          <ModalHeader toggle={toggleDetailModal}>Details</ModalHeader>
          <ModalBody>
            {selectedEvent ? (
              <>
                <h5>{selectedEvent.title}</h5>
                <p>
                  <strong>Type:</strong> {selectedEvent.type}
                </p>
                <p>
                  <strong>Starttijd:</strong>{" "}
                  {moment(selectedEvent.start).format("DD-MM-YYYY HH:mm")}
                </p>
                <p>
                  <strong>Eindtijd:</strong>{" "}
                  {moment(selectedEvent.end).format("DD-MM-YYYY HH:mm")}
                </p>
                <p>
                  <strong>Beschrijving:</strong>{" "}
                  {selectedEvent.description || "Geen beschrijving"}
                </p>
                {selectedEvent.offerte && (
                  <>
                    <p>
                      <strong>Klant:</strong>
                      <br />
                      {selectedEvent.offerte.customerDetails.firstName} {selectedEvent.offerte.customerDetails.lastName}
                      <br />
                      {selectedEvent.offerte.customerDetails.straat}{" "}
                      {selectedEvent.offerte.customerDetails.huisnummer}
                      <br />
                      {selectedEvent.offerte.customerDetails.postcode}{" "}
                      {selectedEvent.offerte.customerDetails.plaats}
                      <br />
                      {selectedEvent.offerte.customerDetails.phone}
                      <br />
                      {selectedEvent.offerte.customerDetails.email}
                    </p>
                  </>
                )}
                {selectedEvent.offerte && (
                  <p>
                    <strong>Offerte:</strong>
                    <br />
                    {selectedEvent.offerte.selectedProduct.naam}
                    <br />
                    <a
                      href={`/offertes/${selectedEvent.offerte.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Bekijk offerte
                    </a>
                  </p>
                )}
              </>
            ) : (
              <p>Geen details beschikbaar.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDetailModal}>
              Sluiten
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default AgendaPage;
