import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Button } from "reactstrap";
import { FaLink } from "react-icons/fa";

const AdminPackagesPage = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const packagesRef = collection(db, "packages");
        const q = query(packagesRef, orderBy("naam", "desc"), limit(10));
        const querySnapshot = await getDocs(q);
        const fetchedPackages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log(fetchedPackages);
        setPackages(fetchedPackages);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, []);

  return (
    <div className="widget package-widget">
      <div className="widget-header">
        <h3>Nieuwste Pakketten</h3>
        <Button
          color="primary"
          className="link-button"
          onClick={() => (window.location.href = "/admin/pakketten/add")}
        >
          Pakket Toevoegen
        </Button>
      </div>
      <div className="widget-content">
        <table
          className="package-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th style={{
                textAlign: "left",
                padding: "8px",
                borderBottom: "2px solid #ddd",
              }}>Actief</th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Naam
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Prijs
              </th>
              {/* <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Producten</th> */}
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Datum
              </th>
            </tr>
          </thead>
          <tbody>
            {packages.length > 0 ? (
              packages.map((packageItem) => (
                <tr
                  key={packageItem.id}
                  style={{ borderBottom: "1px solid #ddd" }}
                  onClick={() =>
                    (window.location.href = `/admin/pakketten/edit/${packageItem.id}`)
                  }
                >
                  <td style={{ padding: "8px" }}>{packageItem.active ? "Actief" : "Inactief"}</td>
                  <td style={{ padding: "8px" }}>
                    {packageItem.naam || "Onbekend"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    €{packageItem.prijs?.toLocaleString() || "N/A"}
                  </td>
                  {/* <td style={{ padding: "8px" }}>{packageItem.products?.length || 0}</td> */}
                  <td style={{ padding: "8px" }}>
                    {packageItem.createdAt
                      ?.toDate()
                      .toLocaleDateString("nl-NL") || "Onbekend"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                  Geen pakketten beschikbaar
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPackagesPage;
