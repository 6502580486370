import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../firebaseConfig";
import "./Login.css";
import Logo from "../Assets/logo.webp";
import { Button } from "reactstrap";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
    } catch (err) {
      setError("Login mislukt. Controleer je gegevens.");
    }
  };

  return (
    <div className="content">
      <div className="logo-image">
        <img src={Logo} alt="logo" />
      </div>
      <div className="app-container login">
        <form onSubmit={handleLogin}>
          <label htmlFor="email">E-mail:</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label htmlFor="password">Wachtwoord:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {error && <p className="error-message">{error}</p>}

          <Button
            className="submit-button"
            type="submit"
            block={true}
            color="success"
            size="lg"
          >
            Inloggen
          </Button>
          {/* <a href="#" onClick={()=> console.log("Wachtwoord vergeten")}>Wachtwoord vergeten?</a> */}
        </form>
      </div>
    </div>
  );
}

export default Login;
