import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase Firestore
import { collection, getDocs } from "firebase/firestore";
import { Row, Col, FormGroup, Label, Input, Button } from "reactstrap";

const ProductDependencies = ({ productData, setProductData }) => {
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "packages"));
        const fetchedPackages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(fetchedPackages);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching packages:", err);
        setError("Failed to load packages.");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  const handleAddDependency = () => {
    setProductData((prev) => ({
      ...prev,
      dependencies: [...(prev.dependencies || []), { packageId: "", type: "required" }],
    }));
  };

  const handleRemoveDependency = (index) => {
    setProductData((prev) => ({
      ...prev,
      dependencies: prev.dependencies.filter((_, i) => i !== index),
    }));
  };

  const handleDependencyChange = (index, field, value) => {
    setProductData((prev) => ({
      ...prev,
      dependencies: prev.dependencies.map((dependency, i) =>
        i === index ? { ...dependency, [field]: value } : dependency
      ),
    }));
  };

  const handleSelectAll = (type = "required") => {
    const allDependencies = packages.map((pkg) => ({
      packageId: pkg.id,
      type,
    }));

    setProductData((prev) => ({
      ...prev,
      dependencies: allDependencies,
    }));
  };

  if (loading) return <div>Loading packages...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div>
      <h5>Koppel Pakketten</h5>
      <div className="mb-3">
        <Button color="primary" onClick={() => handleSelectAll("required")} className="me-2">
          Selecteer Alle (Verplicht)
        </Button>
        <Button color="secondary" onClick={() => handleSelectAll("optional")}>
          Selecteer Alle (Optioneel)
        </Button>
      </div>
      {productData.dependencies?.map((dependency, index) => (
        <Row key={index} className="mb-3">
          <Col md={6}>
            <FormGroup>
              <Label for={`package-${index}`}>Selecteer Pakket</Label>
              <Input
                type="select"
                id={`package-${index}`}
                value={dependency.packageId}
                onChange={(e) => handleDependencyChange(index, "packageId", e.target.value)}
              >
                <option value="">Kies een pakket</option>
                {packages.map((packageItem) => (
                  <option key={packageItem.id} value={packageItem.id}>
                    {packageItem.naam} - €{packageItem.prijs?.toLocaleString() || "N/A"}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup>
              <Label for={`type-${index}`}>Type</Label>
              <Input
                type="select"
                id={`type-${index}`}
                value={dependency.type}
                onChange={(e) => handleDependencyChange(index, "type", e.target.value)}
              >
                <option value="required">Verplicht</option>
                <option value="optional">Optioneel</option>
              </Input>
            </FormGroup>
          </Col>
          <Col md={2} className="d-flex align-items-center">
            <Button
              color="danger"
              size="sm"
              onClick={() => handleRemoveDependency(index)}
            >
              Verwijderen
            </Button>
          </Col>
        </Row>
      ))}
      <Button color="primary" onClick={handleAddDependency}>
        Voeg Pakket Toe
      </Button>
    </div>
  );
};

export default ProductDependencies;
