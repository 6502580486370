import React, { act, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  Container,
} from "reactstrap";

const AccordionSteps = ({
  selectedProduct,
  onSubmit,
  formData,
  selectedExtras,
  lead
}) => {
  const [activeStep, setActiveStep] = useState(1);
  const [discounts, setDiscounts] = useState([
    {
      id: 1,
      name: "Early adopter korting",
      value: 5,
      type: "percentage",
      selected: false,
    },
    {
      id: 2,
      name: "Seizoenskorting",
      value: 200,
      type: "fixed",
      selected: false,
    },
  ]);

  const [customDiscount, setCustomDiscount] = useState({
    name: "Custom korting",
    value: 0,
    type: "fixed", // Default type: "fixed" or "percentage"
    selected: false,
  });

  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // console.log(lead)
  useEffect(() => {
    if (lead) {
      if (!customerDetails.firstName) {
        Object.keys(customerDetails).map(a => {
          setCustomerDetails((prev) => ({ ...prev, [a]: lead[a] }));
        })
      }
    }
  }, [lead]);
  useEffect(() => {
    if (selectedProduct) {
      const initialDiscounts = [
        {
          id: 1,
          name: "Early adopter korting",
          value: 5,
          type: "percentage",
          selected: false,
        },
        {
          id: 2,
          name: "Seizoenskorting",
          value: 200,
          type: "fixed",
          selected: false,
        },
      ];
      setDiscounts(
        selectedProduct.appliedDiscounts
          ? initialDiscounts.map((d) => ({
            ...d,
            selected: selectedProduct.appliedDiscounts.some(
              (ad) => ad.id === d.id
            ),
          }))
          : initialDiscounts
      );
    }
  }, [selectedProduct]);

  const [errors, setErrors] = useState({});

  const VAT_RATE = 0.21; // 21% VAT rate

  const getPriceIncludingVAT = () => {
    return (selectedProduct.prijs * (1 + VAT_RATE)).toFixed(2);
  };

  const getPriceVAT = () => {
    return (selectedProduct.prijs * VAT_RATE).toFixed(2);
  };

  const getPriceExcludingVAT = () => {
    return (selectedProduct.prijs * 1).toFixed(2);
  };

  const toggleDiscount = (index) => {
    setDiscounts((prev) =>
      prev.map((discount, i) =>
        i === index ? { ...discount, selected: !discount.selected } : discount
      )
    );
  };

  const toggleCustomDiscount = () => {
    setCustomDiscount((prev) => ({ ...prev, selected: !prev.selected }));
  };

  const calculateTotalPrice = () => {
    // Calculate the base price including extras
    const basePrice =
      parseFloat(Number(selectedProduct.prijs) || 0) +
      selectedExtras.reduce((total, extra) => {
        return (
          total +
          (extra.type === "quantity"
            ? Number(extra.price) * (Number(extra.quantity) || 0)
            : Number(extra.price))
        );
      }, 0);

    // Apply discounts
    const discountedPrice = discounts
      .filter((discount) => discount.selected)
      .concat(customDiscount.selected ? customDiscount : [])
      .reduce((total, discount) => {
        if (discount.type === "percentage") {
          return total - (discount.value / 100) * total;
        }
        return total - discount.value;
      }, basePrice);

    // Calculate VAT
    const VAT = discountedPrice * VAT_RATE;
    const totalIncludingVAT = discountedPrice + VAT;

    return {
      basePrice: basePrice.toFixed(2),
      discountedPrice: discountedPrice.toFixed(2),
      VAT: VAT.toFixed(2),
      totalIncludingVAT: totalIncludingVAT.toFixed(2),
    };
  };

  const getPriceDetails = calculateTotalPrice;

  const calculateDiscount = () => {
    const priceIncludingVAT = parseFloat(getPriceExcludingVAT()); // Parse to ensure it's a number
    const appliedDiscounts = discounts.filter((d) => d.selected);
    if (customDiscount.selected) appliedDiscounts.push(customDiscount);

    const discountedPrice = appliedDiscounts.reduce((total, discount) => {
      if (discount.type === "percentage") {
        return total - (discount.value / 100) * total;
      }
      return total - discount.value;
    }, priceIncludingVAT);

    return discountedPrice.toFixed(2);
  };

  const handleCustomDiscountChange = (e) => {
    const { name, value } = e.target;
    setCustomDiscount((prev) => ({
      ...prev,
      [name]: name === "value" ? parseFloat(value) || 0 : value,
    }));
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prev) => ({ ...prev, [name]: value }));
  };

  // console.log(customerDetails)
  const validateStep = (step) => {
    const validationErrors = {};
    if (step === 2) {
      // Validate Klantgegevens (Step 2)
      if (!customerDetails.firstName) {
        validationErrors.firstName = "Voornaam is verplicht.";
      }
      if (!customerDetails.lastName) {
        validationErrors.lastName = "Achternaam is verplicht.";
      }
      if (
        !customerDetails.email ||
        !/\S+@\S+\.\S+/.test(customerDetails.email)
      ) {
        validationErrors.email = "Een geldig e-mailadres is verplicht.";
      }
      if (!customerDetails.phone || customerDetails.phone.length < 10) {
        validationErrors.phone = "Een geldig telefoonnummer is verplicht.";
      }
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };
  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep < 4) {
        setActiveStep((prev) => prev + 1);
      } else {
        onSubmit({
          discounts,
          customerDetails,
          finalPrice: selectedProduct.price, // Replace with your final price calculation
        });
      }
    }
  };

  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleSubmit = (action) => {
    const priceDetails = calculateTotalPrice();
    // Filter applied discounts
    const appliedDiscounts = discounts
      .filter((discount) => discount.selected)
      .concat(customDiscount.selected ? customDiscount : []);
    // adding address to customer details
    customerDetails.straat = formData.straat;
    customerDetails.huisnummer = formData.huisnummer;
    customerDetails.toevoeging = formData.toevoeging;
    customerDetails.postcode = formData.postcode;
    customerDetails.plaats = formData.plaats;
    customerDetails.provincie = formData.provincie;

    // Prepare data for submission
    const finalData = {
      selectedProduct: selectedProduct, // Product details
      customerDetails: customerDetails, // Customer information
      selectedExtras: selectedExtras, // Selected additional products or services
      appliedDiscounts: appliedDiscounts, // All discounts including custom
      finalPrice: priceDetails.discountedPrice,
      priceDetails: priceDetails,
    };

    onSubmit(finalData, action);
  };
  // console.log(selectedProduct)
  return (
    <Container className="accordion-steps">
      <Row>
        {/* Accordion Steps */}
        <Col md={8}>
          <div className={`accordion-step ${activeStep === 1 ? "active" : ""}`}>
            <h3>1. Kortingen</h3>
            {activeStep === 1 && (
              <Card className="p-4 shadow-sm">
                <h5>Selecteer kortingen</h5>
                {discounts.map((discount, index) => (
                  <Row key={discount.id} className="align-items-center mb-3">
                    <Col md={6}>
                      <h6>{discount.name}</h6>
                    </Col>
                    <Col md={3}>
                      <p>
                        {discount.type === "percentage"
                          ? `-${discount.value}%`
                          : `-€${discount.value}`}
                      </p>
                    </Col>
                    <Col md={3}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={discount.selected}
                            onChange={() => toggleDiscount(index)}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}

                {/* Custom Discount */}
                <h5>Voeg een aangepaste korting toe</h5>
                <Row className="align-items-center mb-3">
                  <Col md={4}>
                    <Input
                      type="text"
                      name="name"
                      value={customDiscount.name}
                      onChange={handleCustomDiscountChange}
                      placeholder="Naam van korting"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="number"
                      name="value"
                      value={customDiscount.value}
                      onChange={handleCustomDiscountChange}
                      placeholder="Waarde"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="select"
                      name="type"
                      value={customDiscount.type}
                      onChange={handleCustomDiscountChange}
                    >
                      <option value="fixed">Vast bedrag (€)</option>
                      <option value="percentage">Percentage (%)</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={customDiscount.selected}
                          onChange={toggleCustomDiscount}
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <Button color="success" onClick={handleSubmit}>
                      Verzenden
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* Customer Details Step */}
          <div className={`accordion-step ${activeStep === 2 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(2)}>2. Klantgegevens</h3>
            {activeStep === 2 && (
              <Card className="p-4 shadow-sm">
                <h5>Vul klantgegevens in</h5>
                <Row>
                  {/* Name Field */}
                  <Col md={6}>
                    <FormGroup>
                      <Label for="firstName">Voornaam</Label>
                      <Input
                        type="text"
                        name="firstName"
                        value={customerDetails.firstName}
                        onChange={handleCustomerChange}
                        invalid={!!errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="text-danger">{errors.firstName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="lastName">Achternaam</Label>
                      <Input
                        type="text"
                        name="lastName"
                        value={customerDetails.lastName}
                        onChange={handleCustomerChange}
                        invalid={!!errors.lastName}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.lastName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        value={customerDetails.email}
                        onChange={handleCustomerChange}
                        invalid={!!errors.email}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Telefoon</Label>
                      <Input
                        type="text"
                        name="phone"
                        value={customerDetails.phone}
                        onChange={handleCustomerChange}
                        invalid={!!errors.phone}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Address Section */}
                <Row className="mt-4">
                  <Col md={8}>
                    <h5>Adres</h5>
                    <p>
                      {formData.straat} {formData.huisnummer}{" "}
                      {formData.toevoeging ? formData.toevoeging : ""}
                      <br /> {formData.postcode} {formData.plaats}{" "}
                    </p>
                  </Col>
                </Row>
                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <Button color="primary" onClick={handleSubmit}>
                      Verzenden
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* Product Step */}
          <div className={`accordion-step ${activeStep === 3 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(3)}>3. Productgegevens</h3>
            {activeStep === 3 && (
              <Card className="p-4 shadow-sm">
                <h5>Overzicht van geselecteerde producten</h5>
                <div>
                  <img
                    src={selectedProduct.image}
                    alt={selectedProduct.name}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                  <h6>{selectedProduct.name}</h6>
                </div>
                <p>
                  <strong>Product:</strong> {selectedProduct.naam}
                </p>
                {selectedExtras.length > 0 && (
                  <p>
                    <ul>
                      {selectedExtras.map((a, i) => {
                        // console.log(a)
                        return (
                          <li key={i}>
                            {a.name}:{" "}
                            {a.type === "quantity" && <>X {a.quantity}</>}
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                )}

                <p className="pricing">
                  <strong>Prijs (excl. BTW):</strong>{" "}
                  <span>€{getPriceDetails().basePrice}</span>
                </p>
                {getPriceDetails().basePrice -
                  getPriceDetails().discountedPrice >
                  0 && (
                    <>
                      <p className="pricing discount">
                        <strong>- Korting:</strong>{" "}
                        <span>
                          - €
                          {(
                            getPriceDetails().basePrice -
                            getPriceDetails().discountedPrice
                          ).toLocaleString()}
                        </span>
                      </p>
                      <p className="pricing">
                        <strong>Totaal na korting : </strong>{" "}
                        <span>
                          €{getPriceDetails().discountedPrice.toLocaleString()}
                        </span>
                      </p>
                    </>
                  )}

                <div className="separator price"></div>

                <p className="pricing">
                  <strong>BTW (21%):</strong>{" "}
                  <span>€{getPriceDetails().VAT}</span>
                </p>
                <div className="separator price"></div>

                <p className="pricing total-price">
                  <strong>Totaal (incl. BTW):</strong>{" "}
                  <span>€{getPriceDetails().totalIncludingVAT}</span>
                </p>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <Button color="success" onClick={handleSubmit}>
                      Verzenden
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* Offerte Step */}
          <div className={`accordion-step ${activeStep === 4 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(4)}>4. Offerte</h3>
            {activeStep === 4 && (
              <Card className="p-4 shadow-sm">
                <h5>Bevestig en verstuur offerte</h5>
                <p>
                  Door op "Verzenden" te klikken, bevestigt u de geselecteerde
                  kortingen en ingevulde gegevens.
                </p>
                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <div className="submit-buttons">
                      <Button
                        className="mr-4"
                        color="warning"
                        onClick={() => handleSubmit("saveAsConcept")}
                      >
                        Opslaan als concept
                      </Button>
                      <Button
                        color="success"
                        onClick={() => handleSubmit("sendToClient")}
                      >
                        Verzenden naar klant
                      </Button>
                    </div>
                  )}
                </div>
              </Card>
            )}
          </div>
        </Col>

        {/* Overview Sidebar */}
        <Col md={4}>
          <Card className="p-4 shadow-sm">
            <h5>Overzicht</h5>
            <div>
              <Row style={{ marginBottom: 40, marginTop: 20 }}>
                <Col>
                  <img
                    src={selectedProduct.images[0]}
                    alt={selectedProduct.naam}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Col>
                <Col xs={6}>
                  <h5 style={{ margin: "20px 0" }}>{selectedProduct.naam}</h5>
                </Col>
              </Row>
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedProduct.beschrijving,
                }}
              ></p>
              <div className="separator price"></div>

              <h5>Meerwerk</h5>
              {selectedExtras.length > 0 && (
                <p>
                  <ul>
                    {selectedExtras.map((a, i) => {
                      // console.log(a)
                      return (
                        <li key={i}>
                          {a.name}:{" "}
                          {a.type === "quantity" && <>X {a.quantity}</>}
                        </li>
                      );
                    })}
                  </ul>
                </p>
              )}
              <div className="separator divider price"></div>

              <p className="pricing">
                Prijs (excl. BTW): <span>€{getPriceDetails().basePrice}</span>
              </p>
              {/* {console.log(getPriceDetails())} */}
              {getPriceDetails().basePrice - getPriceDetails().discountedPrice >
                0 && (
                  <>
                    <p className="pricing discount">
                      - Korting:{" "}
                      <span>
                        - €
                        {(
                          getPriceDetails().basePrice -
                          getPriceDetails().discountedPrice
                        ).toLocaleString()}
                      </span>
                    </p>
                    <p className="pricing">
                      Prijs na korting (excl. BTW):{" "}
                      <span>
                        €{getPriceDetails().discountedPrice.toLocaleString()}
                      </span>
                    </p>
                  </>
                )}

              <div className="separator price"></div>

              <p className="pricing">
                BTW (21%): <span>€{getPriceDetails().VAT}</span>
              </p>
              <div className="separator price"></div>

              <p className="pricing total-price">
                <strong>Totaal (incl. BTW):</strong>{" "}
                <span>€{getPriceDetails().totalIncludingVAT}</span>
              </p>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default AccordionSteps;
