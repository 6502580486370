import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { UserProvider, useUser } from "./context/UserContext"; // Import UserContext
import Login from "./pages/Login";
import Dashboard from "./pages/admin/Dashboard";
import Rekentool from "./pages/Offerte";
import Header from "./components/Header";
import StartPage from "./pages/StartPage";
import "./App.css";
import ProductPage from "./pages/ProductPage";
import SingleProductPage from "./pages/SingleProduct";
import ProductAddPage from "./pages/admin/ProductAddPage";
import ProductsPage from "./pages/ProductsPage";
import ProductEditPage from "./pages/admin/ProductEditPage";
import PackagesPage from "./pages/Packages";
import PackageEditPage from "./pages/admin/PackageEdit";
import OffertesOverview from "./pages/OffertesOverview";
import SingleOffertePage from "./pages/SingleOffertePage";
import EditOffertePage from "./pages/OfferteEdit";
import AdminPanel from "./pages/admin/AdminPanel";
import TakenPage from "./pages/Taken";
import AgendaPage from "./pages/AgendaPage";
import CustomerOfferPage from "./pages/Customer/CustomerOfferPage";
import LeadsPage from "./pages/LeadsPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const AppContent = () => {
  const { user, loading, logout } = useUser();
  const location = useLocation();

  // Check if the current route is an admin route
  const isAdminRoute = location.pathname.startsWith("/admin");

  if (loading) {
    return <div className="loading">Laden...</div>;
  }

  return (
    <div className="main">
      {user ? (
        <>
          {isAdminRoute ? <></> : <Header onLogout={logout} user={user} />}
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/start" element={<StartPage />} />
            <Route path="/offerte" element={<Rekentool />} />
            <Route path="/products" element={<ProductsPage />} />

            <Route path="/add/product" element={<ProductAddPage />} />
            <Route path="/edit/product/:id" element={<ProductEditPage />} />
            <Route
              path="/products/:productId"
              element={<SingleProductPage />}
            />
            <Route path="/packages" element={<PackagesPage />} />
            <Route path="/add-package" element={<PackageEditPage />} />
            <Route path="/edit-package/:id" element={<PackageEditPage />} />

            <Route path="/offertes" element={<OffertesOverview />} />
            <Route path="/offertes/:id" element={<SingleOffertePage />} />
            <Route path="/offertes/edit/:id" element={<EditOffertePage />} />

            <Route path="/leads" element={<LeadsPage />} />
            <Route path="/taken" element={<TakenPage />} />
            <Route path="/agenda" element={<AgendaPage />} />

            {/* Admin Routes */}
            <Route path="/admin/*" element={<AdminPanel />} />

            {/* Redirect any undefined admin route to dashboard */}
            <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
            <Route
              path="/public/offerte/:offerId/:passcode"
              element={<CustomerOfferPage />}
            />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route
            path="/public/offerte/:offerId/:passcode"
            element={<CustomerOfferPage />}
          />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
};

const App = () => (
  <UserProvider>

    <AppContent />
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  </UserProvider>
);

export default App;
