import React from "react";
import { NavLink } from "react-router-dom";
import {
  FaTachometerAlt,
  FaUsers,
  FaTasks,
  FaFileAlt,
  FaBox,
} from "react-icons/fa";
import logo from "../../Assets/logo-white.webp";
import "./SideBar.css";
import { FaBoxesStacked } from "react-icons/fa6";
import { BsBoxSeam, BsBoxSeamFill } from "react-icons/bs";
import usePageIdentifier from "../../utils/usePageIdentifier";

const Sidebar = () => {
  const currentPage = usePageIdentifier();
  return (
    <div className="sidebar">
      <div className="logo">
        <img
          src={logo}
          alt="Thuisbatterij Experts"
          style={{ height: "100%" }}
        />
      </div>
      <nav className="menu">
        <ul>
          <li>
            <NavLink
              to="/admin/dashboard"
              className={currentPage === "Dashboard" ? "active" : ""}
              end
            >
              <FaTachometerAlt /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/offertes"
              className={currentPage === "Offertes" ? "active" : ""}
              end
            >
              <FaFileAlt /> Offertes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/producten"
              className={currentPage === "Producten" ? "active" : ""}
              end
            >
              <BsBoxSeam /> Producten
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/pakketten"
              className={currentPage === "Pakketten" ? "active" : ""}
              end
            >
              <BsBoxSeamFill /> Pakketten
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/users"
              className={currentPage === "Users" ? "active" : ""}
              end
            >
              <FaUsers /> Gebruikers
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
