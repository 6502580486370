import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import { collection, getDocs } from "firebase/firestore";
import PackageCard from "../components/PackageCard"; // Assume this is similar to ProductCard but for packages
import "./PackageCard.css"; // Style for the page layout
import PackageModal from "../components/PackageModal"; // Assume this is similar to ProductModal but for packages

const ProductStep = ({ onSelect, selectedPackage }) => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [modalPackage, setModalPackage] = useState(null);

  // Filters
  const [searchQuery, setSearchQuery] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "packages"));
        const packageList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })).filter((pkg) => pkg.active); // Filter for active packages;
        setPackages(packageList);
        setFilteredPackages(packageList);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching packages:", err);
        setError("Failed to load packages. Please try again.");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  // Filter logic
  useEffect(() => {
    let filtered = packages;

    if (searchQuery) {
      filtered = filtered.filter((pkg) =>
        pkg.naam.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (categoryFilter) {
      filtered = filtered.filter((pkg) => pkg.category === categoryFilter);
    }

    if (minPrice) {
      filtered = filtered.filter((pkg) => parseFloat(pkg.prijs) >= parseFloat(minPrice));
    }

    if (maxPrice) {
      filtered = filtered.filter((pkg) => parseFloat(pkg.prijs) <= parseFloat(maxPrice));
    }

    setFilteredPackages(filtered);
  }, [searchQuery, categoryFilter, minPrice, maxPrice, packages]);

  const handleOpenModal = (packageItem) => {
    setModalPackage(packageItem);
  };

  const handleCloseModal = () => {
    setModalPackage(null);
  };

  const onSelectModal = (packageItem) => {

    onSelect(packageItem);
    setModalPackage(null);
  };

  if (loading) return <div>Loading packages...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="package-page">
      <h2>Uw selectie</h2>
      {/* Filters Section */}
      <div className="filters">
        <input
          type="text"
          placeholder="Zoek op naam of kWh..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* <select
          value={categoryFilter}
          onChange={(e) => setCategoryFilter(e.target.value)}
        >
          <option value="">Alle categorieën</option>
          <option value="thuisbatterij">Thuisbatterij</option>
          <option value="zonnepanelen">Zonnepanelen</option>
          <option value="omvormers">Omvormers</option>
        </select> */}
        <input
          type="number"
          placeholder="Min. prijs (€)"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max. prijs (€)"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
      </div>
      <div className="tags-list">
        <div className="tag trans">Vaak gezocht....</div>
        <div className="tag" onClick={() => setSearchQuery("growatt")}>
          Growatt
        </div>
        <div className="tag" onClick={() => setSearchQuery("dyness")}>
          Dyness
        </div>
      </div>
      <div className="package-grid">
        {filteredPackages.map((packageItem) => (
          <PackageCard
            key={packageItem.id}
            packageItem={packageItem}
            isSelected={selectedPackage && selectedPackage.id === packageItem.id}
            onSelect={() => onSelect(packageItem)}
            onOpenModal={() => handleOpenModal(packageItem)}
          />
        ))}
      </div>
      {modalPackage && (
        <PackageModal
          packageItem={modalPackage}
          onClose={handleCloseModal}
          onSelect={() => onSelectModal(modalPackage)}
        />
      )}
    </div>
  );
};

export default ProductStep;
