import React from "react";
import { Modal, ModalHeader, ModalBody, Button } from "reactstrap";

const PdfViewerModal = ({ isOpen, toggle, pdfBlob }) => {
  if (!pdfBlob) {
    return null;
  }

  // Convert blob to URL for rendering in iframe
  // console.log(pdfBlob)
  const pdfUrl = URL.createObjectURL(pdfBlob);
  // console.log(pdfBlob)
  return (
    <Modal className="modal-offerte" isOpen={isOpen} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle}>Bekijk PDF</ModalHeader>
      <ModalBody style={{ height: "100%" }}>
        <iframe
          src={pdfUrl}
          title="PDF Viewer"
          width="100%"
          height="100%"
          style={{ border: "none" }}
        ></iframe>
      </ModalBody>
      <div className="d-flex justify-content-end p-3">
        {/* <Button color="primary" onClick={() => window.open(pdfUrl, "_blank")}>
                    Download PDF
                </Button> */}
        <Button color="secondary" onClick={toggle} className="ms-2">
          Sluiten
        </Button>
      </div>
    </Modal>
  );
};

export default PdfViewerModal;
