import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Button, Card, FormGroup, Input, Label, Row, Col, Container } from "reactstrap";
import axios from "axios";
import Offerte1 from "../templates/offertes/Offerte1";
import "./OfferteEdit.css"
import PdfGenerator from "../components/PdfGenerator";
import PdfViewerModal from "../components/PdfViewerModal";

const EditAccordion = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [offerteData, setOfferteData] = useState({
        agent: null,
        createdAt: null,
        id: null,
        status: null,
        updatedAt: null,
        customerDetails: {},
        selectedProduct: null,
        selectedProducts: [],
        appliedDiscounts: [],
        selectedExtras: [],
        priceDetails: { basePrice: 0, VAT: 0, totalIncludingVAT: 0, discountedPrice: 0 },
        finalPrice: 0,
    });
    const [activeStep, setActiveStep] = useState(1);
    const [loading, setLoading] = useState(true);
    const [address, setAddress] = useState({ postalCode: "", houseNumber: "", toevoeging: "" });
    const [addressDetails, setAddressDetails] = useState({});
    const [products, setProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]); // List of products available for selection
    const [packages, setPackages] = useState([]); // List of available packages
    const [selectedPackage, setSelectedPackage] = useState(null); // Selected package
    const [pdfBlob, setPdfBlob] = useState(null)
    const [isModalOpen, setModalOpen] = useState(false);
    const toggleModal = () => setModalOpen(!isModalOpen);
    useEffect(() => {
        const fetchOfferte = async () => {
            try {
                const docRef = doc(db, "offertes", id);
                const docSnap = await getDoc(docRef);
                // console.log(docSnap.data())
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setOfferteData({
                        ...data,
                        selectedProducts: data.selectedProducts || [],
                        appliedDiscounts: data.appliedDiscounts || [],
                    });
                    setAddress({
                        postalCode: docSnap.data().customerDetails?.postcode || "",
                        houseNumber: docSnap.data().customerDetails?.huisnummer || "",
                    });

                    const packagesSnapshot = await getDocs(collection(db, "packages"));
                    const fetchedPackages = packagesSnapshot.docs.map((doc) => ({
                        id: doc.id,
                        ...doc.data(),
                    }));
                    setPackages(fetchedPackages);
                    fetchProducts(docSnap.data().selectedProduct.id)
                } else {
                    console.error("No such document!");
                    navigate("/offertes");
                }
            } catch (error) {
                console.error("Error fetching offerte:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchOfferte();
        fetchProducts();
    }, [id, navigate]);

    useEffect(() => {

        fetchProducts();

    }, [offerteData.selectedProduct, offerteData.selectedExtras, activeStep]);

    useEffect(() => {
        if (offerteData.selectedProduct) {
            calculatePrices(offerteData);
            // console.log("changes")
        }

    }, [products, offerteData.appliedDiscounts]);

    const fetchProducts = async () => {
        try {
            const productsSnapshot = await getDocs(collection(db, "productsNew"));
            const fetchedProducts = productsSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
                selected: false, // Initialize selected state
                quantity: 0, // Initialize quantity
            }));

            // Merge `offerteData.selectedExtras` into `fetchedProducts`
            const mergedProducts = fetchedProducts.map((product) => {
                const matchingExtra = offerteData.selectedExtras.find((extra) => extra.id === product.id);
                if (matchingExtra) {
                    // Override `selected` and `quantity` from `selectedExtras`
                    return {
                        ...product,
                        selected: matchingExtra.selected || false,
                        quantity: matchingExtra.quantity || 0,
                    };
                }
                return product;
            });

            const filteredProducts = mergedProducts.filter((product) =>
                product.dependencies?.some((dep) => dep.packageId === offerteData.selectedProduct?.id)
            );

            // Add custom products from `offerteData.selectedExtras`
            const customProducts = offerteData.selectedExtras.filter((extra) => extra.isCustom);
            const allProducts = [...filteredProducts, ...customProducts];
            // Merge custom products

            // console.log(allProducts)
            setProducts(allProducts);
            setOfferteData((prev) => ({
                ...prev,
                selectedProducts: allProducts.filter((p) => p.selected),
            }));
        } catch (error) {
            console.error("Error fetching products:", error);
        }
    };

    const VAT_RATE = 0.21; // 21% VAT rate


    const handleProductSelection = (productId) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? { ...product, selected: !product.selected }
                    : product
            )
        );

        setOfferteData((prev) => {
            const product = products.find((p) => p.id === productId);
            const updatedSelectedExtras = product.selected
                ? prev.selectedExtras.filter((p) => p.id !== productId) // Deselect
                : [...prev.selectedExtras, { ...product, quantity: 1 }]; // Select with default quantity

            // Recalculate price details
            const updatedData = {
                ...prev,
                selectedExtras: updatedSelectedExtras,
            };

            return {
                ...updatedData,
                ...calculatePrices(updatedData),
            };
        });
    };

    const handleQuantityChange = (productId, change) => {
        setProducts((prevProducts) =>
            prevProducts.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        quantity: Math.max((product.quantity || 0) + change, 0),
                    }
                    : product
            )
        );

        setOfferteData((prev) => {
            const updatedSelectedExtras = prev.selectedExtras.map((product) =>
                product.id === productId
                    ? {
                        ...product,
                        quantity: Math.max((product.quantity || 0) + change, 0),
                    }
                    : product
            );

            // Recalculate price details
            const updatedData = {
                ...prev,
                selectedExtras: updatedSelectedExtras,
            };

            return {
                ...updatedData,
                ...calculatePrices(updatedData),
            };
        });
    };

    const handlePackageSelection = async (pkg) => {
        setOfferteData((prev) => {
            const updatedData = {
                ...prev,
                selectedProduct: pkg, // Update the selected package
                selectedProducts: [], // Reset selected products
            };

            // Base price (Package price)
            const basePrice = Number(pkg.prijs) || 0;

            // Calculate extra products' price
            const extraProductsPrice = prev.selectedExtras.reduce((total, product) => {
                if (product.type === "quantity") {
                    // Multiply price by quantity for quantity-based products
                    return total + Number(product.price) * (Number(product.quantity) || 1);
                } else if (product.type === "single") {
                    // Add price directly for single-selection products
                    return total + Number(product.price);
                }
                return total; // Fallback for undefined types
            }, 0);

            // Calculate price excluding VAT
            const priceExcludingVAT = basePrice + extraProductsPrice;



            // Apply discounts
            const appliedDiscounts = prev.appliedDiscounts || [];
            const totalDiscount = appliedDiscounts.reduce((total, discount) => {
                if (discount.type === "percentage") {
                    // Apply percentage discount on the base price (before VAT)
                    return total + (discount.value / 100) * priceExcludingVAT;
                } else if (discount.type === "fixed") {
                    // Apply fixed discount
                    return total + discount.value;
                }
                return total; // Fallback for undefined types
            }, 0);
            const discountedPrice = priceExcludingVAT - totalDiscount
            // Ensure the discount does not exceed the total price
            // Calculate VAT
            const VAT = discountedPrice * VAT_RATE;

            // Total price including VAT
            const priceIncludingVAT = discountedPrice + VAT;

            // Update price details
            updatedData.priceDetails = {
                basePrice: priceExcludingVAT.toFixed(2),
                VAT: VAT.toFixed(2),
                totalIncludingVAT: priceIncludingVAT.toFixed(2),
                discountedPrice: discountedPrice.toFixed(2),
            };

            // Final price after discount
            updatedData.finalPrice = priceIncludingVAT.toFixed(2);

            return updatedData;
        });

        // Fetch and update products for the selected package
        await fetchProducts(pkg.id);
    };

    const handleDiscountChange = (index, key, value) => {
        setOfferteData((prev) => {
            const discounts = [...prev.appliedDiscounts];
            discounts[index] = { ...discounts[index], [key]: value };

            // Update discounts and recalculate prices
            const updatedData = {
                ...prev,
                appliedDiscounts: discounts,
            };

            // Recalculate prices with updated discounts
            return {
                ...updatedData,
                ...calculatePrices(updatedData),
            };
        });
    };

    const calculatePrices = (data) => {
        // console.log(data)
        const basePrice = parseFloat(data.selectedProduct?.prijs || 0);

        // Calculate extras price
        const extrasPrice = data.selectedExtras.reduce((total, extra) => {
            if (extra.type === "single" && extra.selected) {
                return total + parseFloat(extra.price || 0);
            }
            if (extra.type === "quantity" && extra.quantity > 0) {
                return total + parseFloat(extra.price || 0) * parseFloat(extra.quantity || 0);
            }
            return total;
        }, 0);

        const priceExcludingVAT = basePrice + extrasPrice;



        // Calculate discounts
        const totalDiscount = data.appliedDiscounts.reduce((total, discount) => {
            if (discount.type === "percentage") {
                return total + (discount.value / 100) * priceExcludingVAT;
            }
            return total + parseFloat(discount.value || 0);
        }, 0);
        const discountedPrice = priceExcludingVAT - totalDiscount
        // Calculate VAT
        const VAT = discountedPrice * VAT_RATE;

        const totalIncludingVAT = discountedPrice + VAT;

        // Return    recalculated details
        const details = {
            basePrice: priceExcludingVAT.toFixed(2),
            VAT: VAT.toFixed(2),
            totalIncludingVAT: totalIncludingVAT.toFixed(2),
            discountedPrice: discountedPrice.toFixed(2),
        }

        return {
            priceDetails: details,
            finalPrice: totalIncludingVAT.toFixed(2),
        };
    };

    const handleUpdateOfferte = async () => {
        try {
            const updatedData = {
                ...offerteData,

                selectedProducts,
            };

            const docRef = doc(db, "offertes", id);
            await updateDoc(docRef, updatedData);
            alert("Offerte bijgewerkt!");
            navigate("/offertes");
        } catch (error) {
            console.error("Error updating offerte:", error);
            alert("Er is iets misgegaan bij het bijwerken van de offerte.");
        }
    };
    const handleNext = () => {
        setActiveStep((prev) => (prev < 4 ? prev + 1 : prev));
    };

    const handlePrevious = () => {
        setActiveStep((prev) => (prev > 1 ? prev - 1 : prev));
    };

    const handleAddressValidation = async () => {
        try {
            const response = await axios.get(
                `https://api.pro6pp.nl/v1/autocomplete?auth_key=${process.env.REACT_APP_PRO6BB
                }&nl_sixpp=${address.postalCode}&streetnumber=${address.houseNumber}`
            );
            if (response.data && response.data.results.length > 0) {
                const result = response.data.results[0];
                setAddressDetails({
                    straat: result.street,
                    plaats: result.city,
                    provincie: result.province,
                });
                setOfferteData({
                    ...offerteData,
                    customerDetails: {
                        ...offerteData.customerDetails,
                        huisnummer: address.houseNumber,
                        toevoeging: address.addon,
                        postcode: address.postalCode,
                        straat: result.street,
                        plaats: result.city,
                        provincie: result.province,
                    },
                })
            } else {
                alert("Invalid postal code or house number.");
            }
        } catch (error) {
            console.error("Error fetching address:", error);
            alert("Error validating address.");
        }
    };

    const generatePdf = async () => {
        console.log("generating pdf")
        const pdfBlob = await Offerte1(offerteData);
        setPdfBlob(pdfBlob);
        console.log(pdfBlob)
        // Open the modal
        toggleModal();
    };

    if (loading) {
        return <div>Loading offerte...</div>;
    }

    // console.log(offerteData)
    return (
        <Container>
            <h2>Edit Offerte</h2>
            <Row>
                <Col md={8}>
                    <div className={`accordion-step ${activeStep === 1 ? "active" : ""}`}>
                        <h3>1. Klantgegevens</h3>
                        {activeStep === 1 && (
                            <Card className="p-4 shadow-sm">
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Voornaam</Label>
                                            <Input
                                                type="text"
                                                value={offerteData.customerDetails?.firstName || ""}
                                                onChange={(e) =>
                                                    setOfferteData({
                                                        ...offerteData,
                                                        customerDetails: {
                                                            ...offerteData.customerDetails,
                                                            firstName: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Achternaam</Label>
                                            <Input
                                                type="text"
                                                value={offerteData.customerDetails?.lastName || ""}
                                                onChange={(e) =>
                                                    setOfferteData({
                                                        ...offerteData,
                                                        customerDetails: {
                                                            ...offerteData.customerDetails,
                                                            lastName: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Email</Label>
                                            <Input
                                                type="email"
                                                value={offerteData.customerDetails?.email || ""}
                                                onChange={(e) =>
                                                    setOfferteData({
                                                        ...offerteData,
                                                        customerDetails: {
                                                            ...offerteData.customerDetails,
                                                            email: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormGroup>
                                            <Label>Telefoonnummer</Label>
                                            <Input
                                                type="text"
                                                value={offerteData.customerDetails?.phone || ""}
                                                onChange={(e) =>
                                                    setOfferteData({
                                                        ...offerteData,
                                                        customerDetails: {
                                                            ...offerteData.customerDetails,
                                                            phone: e.target.value,
                                                        },
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>

                                </Row>
                                <Row>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Postcode</Label>
                                            <Input
                                                type="text"
                                                value={address.postalCode}
                                                onChange={(e) => setAddress({ ...address, postalCode: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Huisnummer</Label>
                                            <Input
                                                type="text"
                                                value={address.houseNumber}
                                                onChange={(e) => setAddress({ ...address, houseNumber: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                        <FormGroup>
                                            <Label>Toevoeging</Label>
                                            <Input
                                                type="text"
                                                value={address.toevoeging}
                                                onChange={(e) => setAddress({ ...address, toevoeging: e.target.value })}
                                            />
                                        </FormGroup>
                                    </Col>
                                    <Col md={3} style={{ alignItems: "center", display: "flex", marginTop: "30px" }}>
                                        <Button color="warning" onClick={handleAddressValidation}>
                                            Haal adres op
                                        </Button>
                                    </Col>
                                </Row>
                                {offerteData.customerDetails.straat && (
                                    <Row>
                                        <Col md={12}>
                                            <p>
                                                <strong>Straat:</strong> {offerteData.customerDetails.straat}
                                                <br />
                                                <strong>Plaats:</strong> {offerteData.customerDetails.plaats}
                                                <br />
                                                <strong>Provincie:</strong> {offerteData.customerDetails.provincie}
                                            </p>
                                        </Col>
                                    </Row>
                                )}
                                <div className="d-flex justify-content-between mt-4">
                                    <Button color="secondary" onClick={handlePrevious} disabled={activeStep === 1}>
                                        Terug
                                    </Button>
                                    <Button color="primary" onClick={handleNext}>
                                        Volgende
                                    </Button>
                                </div>
                            </Card>
                        )}
                    </div>
                    {/* Step 2: Product Selection */}
                    <div className={`accordion-step ${activeStep === 2 ? "active" : ""}`}>
                        <h3>2. Pakketselectie</h3>
                        {activeStep === 2 && (
                            <Card className="p-4 shadow-sm">
                                <h5>Selecteer een pakket</h5>
                                <div className="package-list">
                                    {packages.map((pkg) => {
                                        // console.log(pkg)
                                        // console.log(selectedPackage)
                                        return (
                                            <div key={pkg.id} className={`package-item ${offerteData.selectedProduct.id === pkg.id ? "active" : ""}`}>
                                                <Label check>
                                                    <Input
                                                        type="radio"
                                                        name="package"
                                                        checked={offerteData.selectedProduct.id === pkg.id}
                                                        onChange={() => handlePackageSelection(pkg)}
                                                    />
                                                    {pkg.naam} - €{pkg.prijs.toLocaleString()}
                                                </Label>
                                            </div>
                                        )
                                    })}
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <Button color="secondary" onClick={handlePrevious} disabled={activeStep === 1}>
                                        Terug
                                    </Button>
                                    <Button color="primary" onClick={handleNext} disabled={!offerteData.selectedProduct}>
                                        Volgende
                                    </Button>
                                </div>
                            </Card>
                        )}
                    </div>
                    {/* Step 3: Product Selection */}
                    <div className={`accordion-step ${activeStep === 3 ? "active" : ""}`}>
                        <h3>3. Productselectie</h3>
                        {activeStep === 3 && (
                            <Card className="p-4 shadow-sm">
                                <h5>Selecteer producten</h5>
                                <div className="product-list">
                                    {products.map((product) => {
                                        const isQuantityProduct = product.type === "quantity";

                                        return (
                                            <div key={product.id} className="product-item">
                                                {isQuantityProduct ? (
                                                    <div className="quantity-product">
                                                        <Label>
                                                            <strong>{product.name}</strong> - €{parseFloat(product.price).toLocaleString()}
                                                        </Label>
                                                        <div className="quantity-controls">
                                                            <Button
                                                                size="sm"
                                                                color="secondary"
                                                                onClick={() => handleQuantityChange(product.id, -1)}
                                                                disabled={product.quantity <= 0}
                                                            >
                                                                -
                                                            </Button>
                                                            <Input
                                                                type="number"
                                                                value={product.quantity || 0}
                                                                readOnly
                                                            />
                                                            <Button
                                                                size="sm"
                                                                color="primary"
                                                                onClick={() => handleQuantityChange(product.id, 1)}
                                                            >
                                                                +
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Label check>
                                                        <Input
                                                            type="checkbox"
                                                            checked={product.selected}
                                                            onChange={() => handleProductSelection(product.id)}
                                                        />
                                                        {product.name} - €{parseFloat(product.price).toLocaleString()}
                                                    </Label>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <Button color="secondary" onClick={handlePrevious}>
                                        Terug
                                    </Button>
                                    <Button color="primary" onClick={handleNext}>
                                        Volgende
                                    </Button>
                                </div>
                            </Card>
                        )}
                    </div>
                    {/* Add additional steps */}
                    <div className={`accordion-step ${activeStep === 4 ? "active" : ""}`}>
                        <h3>4. Korting</h3>
                        {/* Step 4: Discount */}
                        {activeStep === 4 && (
                            <Card className="p-4 shadow-sm">

                                <div className="discount-list">
                                    {offerteData.appliedDiscounts.map((discount, index) => (
                                        <Row key={index} className="mb-3 align-items-center">
                                            <Col md={4}>
                                                <Input
                                                    type="text"
                                                    value={discount.name || ""}
                                                    placeholder="Naam van korting"
                                                    onChange={(e) =>
                                                        handleDiscountChange(index, "name", e.target.value)
                                                    }
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <Input
                                                    type="number"
                                                    value={discount.value || ""}
                                                    placeholder="Waarde"
                                                    onChange={(e) =>
                                                        handleDiscountChange(index, "value", parseFloat(e.target.value) || 0)
                                                    }
                                                />
                                            </Col>
                                            <Col md={3}>
                                                <Input
                                                    type="select"
                                                    value={discount.type || "fixed"}
                                                    onChange={(e) =>
                                                        handleDiscountChange(index, "type", e.target.value)
                                                    }
                                                >
                                                    <option value="fixed">Vast bedrag (€)</option>
                                                    <option value="percentage">Percentage (%)</option>
                                                </Input>
                                            </Col>
                                            <Col md={2}>
                                                <Button
                                                    color="danger"
                                                    size="sm"
                                                    onClick={() =>
                                                        setOfferteData((prev) => {
                                                            const discounts = prev.appliedDiscounts.filter(
                                                                (_, i) => i !== index
                                                            );
                                                            const updatedData = {
                                                                ...prev,
                                                                appliedDiscounts: discounts,
                                                            };
                                                            return {
                                                                ...updatedData,
                                                                ...calculatePrices(updatedData),
                                                            };
                                                        })
                                                    }
                                                >
                                                    Verwijderen
                                                </Button>
                                            </Col>
                                        </Row>
                                    ))}
                                </div>
                                <Button
                                    color="secondary"
                                    onClick={() =>
                                        setOfferteData((prev) => {
                                            const discounts = [
                                                ...prev.appliedDiscounts,
                                                { name: "", value: 0, type: "fixed" },
                                            ];
                                            const updatedData = { ...prev, appliedDiscounts: discounts };
                                            return {
                                                ...updatedData,
                                                ...calculatePrices(updatedData),
                                            };
                                        })
                                    }
                                >
                                    Voeg Korting Toe
                                </Button>


                                <div className="d-flex justify-content-between mt-4">
                                    <Button color="secondary" onClick={handlePrevious}>
                                        Terug
                                    </Button>
                                    <Button color="primary" onClick={handleNext}>
                                        Volgende
                                    </Button>
                                </div>
                            </Card>
                        )}
                    </div>
                </Col>
                {/* Sidebar */}
                <Col md={4}>
                    <div className="offerte-watch mb-3">
                        <Button color="success" block={true} onClick={handleUpdateOfferte}>
                            Bijwerken
                        </Button>
                    </div>
                    <div className="offerte-watch mb-3">
                        <Button color={"primary"} block={true} onClick={generatePdf}>Offerte Bekijken</Button>
                    </div>

                    <Card className="p-4 shadow-sm">
                        <h5>Overzicht</h5>
                        <div>
                            <Row style={{ marginBottom: 20, marginTop: 20 }}>
                                <Col>
                                    {/* {console.log(offerteData)} */}
                                    <img
                                        src={offerteData.selectedProduct.images[0]}
                                        alt={offerteData.selectedProduct.naam}
                                        style={{ width: "70%", borderRadius: "4px" }}
                                    />
                                </Col>
                                <Col xs={6}><h5 style={{ margin: "20px 0" }}>{offerteData.selectedProduct.naam}</h5></Col>
                            </Row>
                            <p dangerouslySetInnerHTML={{ __html: offerteData.selectedProduct.beschrijving }}></p>
                            <div className="separator price"></div>
                            <h5>Meerwerk</h5>
                            {offerteData.selectedExtras.length > 0 &&

                                <ul>
                                    {offerteData.selectedExtras.map((a, i) => {
                                        // console.log(a)
                                        return <li key={i}>{a.name}: {a.type === "quantity" && <>X {a.quantity}</>}</li>
                                    })}
                                </ul>

                            }

                            <div className="separator divider price"></div>
                            <p className="pricing">
                                Prijs (excl. BTW):
                                <span>€{offerteData.priceDetails.basePrice}</span>
                            </p>


                            {(Number(offerteData.priceDetails.basePrice) - Number(offerteData.priceDetails.discountedPrice)) > 0 && <>
                                <p className="pricing discount">
                                    - Korting:  <span>- €{(offerteData.priceDetails.basePrice - offerteData.priceDetails.discountedPrice).toLocaleString()}</span>
                                </p>
                                <p className="pricing">
                                    Totaal na korting :  <span>€{offerteData.priceDetails.discountedPrice.toLocaleString()}</span>
                                </p>
                            </>
                            }
                            <div className="separator price"></div>

                            <p className="pricing">
                                BTW (21%):
                                <span>€{offerteData.priceDetails.VAT}</span>
                            </p>
                            <div className="separator price"></div>
                            <p className="pricing total-price">
                                Totaal (incl. BTW):
                                <span>€{offerteData.priceDetails.totalIncludingVAT}</span>
                            </p>
                            <div className="separator price"></div>
                        </div>
                    </Card>
                </Col>
            </Row>
            {/* {activeStep === 4 && (
                <Button color="success" onClick={handleUpdateOfferte}>
                    Bijwerken
                </Button>
            )} */}
            <PdfViewerModal isOpen={isModalOpen} toggle={toggleModal} pdfBlob={pdfBlob} />
        </Container>
    );
};

export default EditAccordion;
