import React from "react";
import Sidebar from "../../components/admin/SideBar";
import AdminContent from "../../components/admin/AdminContent";
import { Routes, Route } from "react-router-dom";
import AdminHeader from "../../components/admin/AdminHeader";
import GraphWidget from "../../components/GraphWidget";
import Dashboard from "../admin/Dashboard";
import AdminOffertesPage from "./Offertes";
import AdminPackagesPage from "./AdminPackagesPage";
import AdminProductsPage from "./AdminProductsPage";
import ProductAddPage from "./ProductAddPage";
import PackageEditPage from "./PackageEdit";
import SingleProductPage from "./SingleProduct";
import BackButton from "../../components/BackButton";
import AdminUsersPage from "./Users";
import { useUser } from "../../context/UserContext";
const AdminPanel = () => {
  const { logout, user } = useUser();
  return (
    <div className="admin-panel">
      <Sidebar />
      <AdminContent>
        <AdminHeader onLogout={logout} user={user} />

        <div className="content-area">
          <Routes>
            <Route path="dashboard" element={<Dashboard />} />
            <Route path="offertes" element={<AdminOffertesPage />} />
            <Route path="/leads" element={<h2>Leads Content</h2>} />
            <Route path="/tasks" element={<h2>Tasks Content</h2>} />
            <Route path="producten" element={<AdminProductsPage />} />
            <Route path="producten/:id" element={<SingleProductPage />} />
            <Route path="producten/add" element={<ProductAddPage />} />
            {/* <Route path="/edit/product/:id" element={<ProductEditPage />} /> */}
            {/* <Route
              path="/products/:productId"
              element={<SingleProductPage />}
            /> */}
            {/* <Route path="/edit-package/:id" element={<PackageEditPage />} /> */}
            <Route path="pakketten" element={<AdminPackagesPage />} />
            <Route path="pakketten/add" element={<PackageEditPage />} />
            <Route path="pakketten/edit/:id" element={<PackageEditPage />} />

            <Route path="users" element={<AdminUsersPage />} />
            <Route path="users/add" element={<AdminUsersPage />} />
            <Route path="users/edit/:id" element={<AdminUsersPage />} />
          </Routes>
        </div>
      </AdminContent>
    </div>
  );
};

export default AdminPanel;
