// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { collection, doc, getDocs, getFirestore, query, updateDoc, where } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Export necessary Firebase services
const auth = getAuth(app); // For authentication
const db = getFirestore(app); // For Firestore database
const storage = getStorage(app); // For Firebase storage

const fetchLeads = async (user) => {

  let leads = []

  let leadsQuery;
  if (user?.role === "admin") {
    leadsQuery = collection(db, "leads");
  } else {
    leadsQuery = query(
      collection(db, "leads"),
      where("agent", "==", user?.uid)
    );
  }
  const leadsSnap = await getDocs(leadsQuery);

  const fetchedLeads = leadsSnap.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return fetchedLeads;

};

const fetchOffertes = async (user) => {

  try {
    let offertesQuery;

    if (user?.role === "admin") {
      // Admin sees all offertes
      offertesQuery = collection(db, "offertes");
    } else {
      // Non-admin sees only their offertes
      offertesQuery = query(
        collection(db, "offertes"),
        where("agent", "==", user?.uid)
      );
    }

    const offertesSnap = await getDocs(offertesQuery);
    const fetchedOffertes = offertesSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return fetchedOffertes;
  } catch (error) {
    console.error("Error fetching offertes:", error);
  }
};

const updateOfferteStatus = async (offerteId, status, additionalData = {}) => {
  const offerteRef = doc(db, "offertes", offerteId);

  try {
    // Prepare the update object
    const updateData = {
      status,
      updatedAt: new Date(), // Update the timestamp
      ...additionalData, // Add any additional fields if provided
    };

    // Update the document in Firestore
    await updateDoc(offerteRef, updateData);

    console.log(`Offerte ${offerteId} updated to status: ${status}`);
  } catch (error) {
    console.error(`Error updating offerte ${offerteId} status:`, error);
    throw error; // Re-throw to handle errors in the calling function
  }
};

export { auth, db, storage, fetchLeads, fetchOffertes, updateOfferteStatus };
