import React from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "reactstrap";
import { FaArrowLeft } from "react-icons/fa";

const BackButton = ({ label = "Terug", customPath = null }) => {
    const navigate = useNavigate();

    const handleBack = () => {
        if (customPath) {
            navigate(customPath);
        } else {
            navigate(-1); // Go back to the previous page
        }
    };

    return (
        <Button color="secondary" onClick={handleBack} className="back-button">
            <FaArrowLeft style={{ marginRight: "5px" }} />
            {label}
        </Button>
    );
};

export default BackButton;
