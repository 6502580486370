import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { db, storage } from "../../firebaseConfig";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProductDependencies from "../../components/ProductDependencies";
import BackButton from "../../components/BackButton";

const ProductEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [originalProductData, setOriginalProductData] = useState(null);
  const [productData, setProductData] = useState({
    name: "",
    category: "",
    description: "",
    features: [],
    specifications: "",
    highlights: [],
    variations: [],
    priceNote: "",
    images: [],
    pdfs: [],
    type: "single", // Default type
    kWh: 0, // kWh value for battery modules
    isBasePackage: false, // Whether the product is a base package
  });

  const [featuresInput, setFeaturesInput] = useState("");
  const [highlightsInput, setHighlightsInput] = useState("");
  const [variationInput, setVariationInput] = useState({ name: "", price: "" });
  const [imageFiles, setImageFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [totalKWh, setTotalKWh] = useState(0); // Tracks the total kWh

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const productRef = doc(db, "productsNew", id);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const product = productSnap.data();
          setProductData({
            name: product.name || "",
            category: product.category || "",
            description: product.description || "",
            features: product.features || [],
            specifications: product.specifications || "",
            highlights: product.highlights || [],
            variations: product.variations || [],
            priceNote: product.priceNote || "",
            images: product.images || [],
            pdfs: product.pdfs || [],
            price: product.price || null,
            dependencies: product.dependencies || [],
            type: product.type || "quantity", // Set type (single/quantity)
            kWh: product.kWh || 0, // kWh value
            isBasePackage: product.isBasePackage || false,
          });

          setOriginalProductData({
            ...product, // Preserve original for comparison
            features: product.features || [],
            highlights: product.highlights || [],
            variations: product.variations || [],
            images: product.images || [],
            pdfs: product.pdfs || [],
            price: product.price || null,
            dependencies: product.dependencies || [],
          });
        } else {
          alert("Product not found!");
          navigate("/products");
        }
      } catch (error) {
        console.error("Error fetching product:", error);
        alert("Failed to load product data.");
      }
    };

    fetchProduct();
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleToggleBasePackage = () => {
    setProductData((prev) => ({
      ...prev,
      isBasePackage: !prev.isBasePackage,
    }));
  };

  const handleAddFeature = () => {
    if (featuresInput.trim()) {
      setProductData((prev) => ({
        ...prev,
        features: [...prev.features, featuresInput.trim()],
      }));
      setFeaturesInput("");
    }
  };

  const handleAddHighlight = () => {
    if (highlightsInput.trim()) {
      setProductData((prev) => ({
        ...prev,
        highlights: [...prev.highlights, highlightsInput.trim()],
      }));
      setHighlightsInput("");
    }
  };

  const handleAddVariation = () => {
    if (variationInput.name && variationInput.price) {
      setProductData((prev) => ({
        ...prev,
        variations: [...prev.variations, variationInput],
      }));
      setVariationInput({ name: "", price: "" });
    }
  };

  const handleRemoveItem = (key, index) => {
    setProductData((prev) => ({
      ...prev,
      [key]: prev[key].filter((_, i) => i !== index),
    }));
  };

  const handleImageUpload = async () => {
    const uploadPromises = imageFiles.map((file) => {
      const storageRef = ref(
        storage,
        `product-images/${file.name}-${Date.now()}`
      );
      return uploadBytes(storageRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handlePdfUpload = async () => {
    const uploadPromises = pdfFiles.map((file) => {
      const storageRef = ref(
        storage,
        `product-pdfs/${file.name}-${Date.now()}`
      );
      return uploadBytes(storageRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const auth = getAuth();
      if (!auth.currentUser) {
        alert("You must be logged in to edit a product.");
        return;
      }

      // Identify deleted files by comparing original and current data
      const deletedImages = originalProductData.images.filter(
        (url) => !productData.images.includes(url)
      );
      const deletedPdfs = originalProductData.pdfs.filter(
        (url) => !productData.pdfs.includes(url)
      );

      // Delete removed files from Firebase Storage
      const deletePromises = [
        ...deletedImages.map((url) => {
          const fileRef = ref(storage, url);
          return deleteObject(fileRef);
        }),
        ...deletedPdfs.map((url) => {
          const fileRef = ref(storage, url);
          return deleteObject(fileRef);
        }),
      ];

      await Promise.all(deletePromises);

      // Upload new files (if any)
      const imageUrls = await handleImageUpload();
      const pdfUrls = await handlePdfUpload();

      const updatedProduct = {
        ...productData,
        images: [...productData.images, ...imageUrls],
        pdfs: [...productData.pdfs, ...pdfUrls],
        updatedAt: new Date(),
      };

      const productRef = doc(db, "productsNew", id);
      await updateDoc(productRef, updatedProduct);

      alert("Product updated successfully!");
      navigate("/products");
    } catch (error) {
      console.error("Error updating product:", error);
      alert("Failed to update product.");
    }

  };
  useEffect(() => {
    // Dynamically calculate total kWh
    if (productData.kWh && productData.isBasePackage) {
      setTotalKWh(productData.kWh);
    }
  }, [productData.kWh, productData.isBasePackage]);
  // console.log(productData);
  return (
    <Container className="product-edit-page mt-4">
      <BackButton />
      <Card className="p-4 shadow-sm" style={{ backgroundColor: "#fff" }}>
        <CardBody>
          <h2 className="text-center mb-4">Product Bewerken</h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col className="text-right">
                <Button type="submit" color="success" className="mt-4">
                  Opslaan
                </Button>
              </Col>
            </Row>
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="name">Product Naam:</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={productData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="category">Categorie:</Label>
                  <Input
                    className="select-input"
                    type="select"
                    name="category"
                    id="category"
                    value={productData.category}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Selecteer een categorie</option>
                    <option value="thuisbatterij">Thuisbatterij</option>
                    <option value="zonnepanelen">Zonnepanelen</option>
                    <option value="omvormers">Omvormers</option>
                    <option value="meerwerk">Meerwerk</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="type">Product input Type:</Label>
                  <Input
                    type="select"
                    name="type"
                    id="type"
                    value={productData.type}
                    onChange={handleChange}
                    required
                  >
                    <option value="single">Single</option>
                    <option value="quantity">Quantity</option>
                  </Input>
                </FormGroup>
              </Col>

            </Row>
            {/* kWh and Base Package */}
            <Row>
              <Col md={12}>
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={productData.isBasePackage}
                      onChange={handleToggleBasePackage}
                    />
                    Basispakket
                  </Label>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="price">Basisprijs:</Label>
                  <Input
                    type="number"
                    name="price"
                    id="price"
                    min={0}
                    value={productData.price}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="kWh">Aantal kWh:</Label>
                  <Input
                    type="number"
                    name="kWh"
                    id="kWh"
                    value={productData.kWh}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>

            </Row>
            {/* Description and Specifications */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Beschrijving:</Label>
                  <ReactQuill
                    value={productData.description}
                    onChange={(value) =>
                      setProductData((prev) => ({
                        ...prev,
                        description: value,
                      }))
                    }
                    theme="snow"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="specifications">Specificaties:</Label>
                  <ReactQuill
                    value={productData.specifications}
                    onChange={(value) =>
                      setProductData((prev) => ({
                        ...prev,
                        specifications: value,
                      }))
                    }
                    theme="snow"
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Highlights */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Belangrijkste Kenmerken:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      value={highlightsInput}
                      onChange={(e) => setHighlightsInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddHighlight}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.highlights.map((highlight, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {highlight}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("highlights", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* Highlights */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Features:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      value={featuresInput}
                      onChange={(e) => setFeaturesInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddFeature}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.features.map((feature, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {feature}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("features", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* Variations */}
            {/* <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Product Variaties:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      placeholder="Naam (e.g., 5kW)"
                      value={variationInput.name}
                      onChange={(e) =>
                        setVariationInput((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                    <Input
                      type="number"
                      placeholder="Prijs"
                      className="ms-2"
                      value={variationInput.price}
                      onChange={(e) =>
                        setVariationInput((prev) => ({
                          ...prev,
                          price: e.target.value,
                        }))
                      }
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddVariation}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.variations.map((variation, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {variation.name} - €{variation.price}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("variations", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row> */}

            {/* Images */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Product Afbeeldingen:</Label>
                  <Input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => setImageFiles(Array.from(e.target.files))}
                  />
                  {productData.images.length > 0 && (
                    <ListGroup className="mt-3">
                      {productData.images.map((url, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Afbeelding {index + 1}
                          </a>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() =>
                              setProductData((prev) => ({
                                ...prev,
                                images: prev.images.filter(
                                  (_, i) => i !== index
                                ),
                              }))
                            }
                          >
                            Verwijderen
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </FormGroup>
              </Col>

              {/* PDFs */}

              <Col md={6}>
                <FormGroup>
                  <Label>PDF Datasheets:</Label>
                  <Input
                    type="file"
                    accept=".pdf"
                    multiple
                    onChange={(e) => setPdfFiles(Array.from(e.target.files))}
                  />
                  {productData.pdfs.length > 0 && (
                    <ListGroup className="mt-3">
                      {productData.pdfs.map((url, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PDF {index + 1}
                          </a>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() =>
                              setProductData((prev) => ({
                                ...prev,
                                pdfs: prev.pdfs.filter((_, i) => i !== index),
                              }))
                            }
                          >
                            Verwijderen
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <ProductDependencies
                  productData={productData}
                  setProductData={setProductData}
                />
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button type="submit" color="success" className="mt-4">
                  Opslaan
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ProductEditPage;
