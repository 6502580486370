import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, fetchLeads, fetchOffertes } from "../firebaseConfig";
import { useUser } from "../context/UserContext";

const TaskModal = ({
  isOpen,
  toggle,
  agents,
  lead,
  initialData = {},
  refreshTasks,
}) => {
  const [leads, setLeads] = useState([]);
  const [offertes, setOffertes] = useState([]);
  const [task, setTask] = useState({
    type: "standaard",
    title: "",
    agent: "",
    startTime: null,
    endTime: null,
    lead: "",
    description: "",
    comments: "",
    ...initialData,
  });
  const { user } = useUser()

  useEffect(() => {

    const fetchData = async () => {
      try {
        const res = await fetchLeads(user);
        const offertes = await fetchOffertes(user)
        setLeads(res);
        setOffertes(offertes)
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    if (!task.title || !task.agent || !task.startTime) {
      alert("Alle velden zijn verplicht.");
      return;
    }

    try {
      if (task.id) {
        // Update existing task
        const taskRef = doc(db, "tasks", task.id);
        await updateDoc(taskRef, task);
      } else {
        // Create new task
        const taskRef = collection(db, "tasks");
        await addDoc(taskRef, { ...task, createdAt: new Date() });
      }
      alert("Taak succesvol opgeslagen.");

      toggle();
    } catch (error) {
      console.error("Error saving task:", error);
      alert("Er is een fout opgetreden bij het opslaan van de taak.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Taak Beheren</ModalHeader>
      <ModalBody>
        <Label>Type</Label>
        <Input
          type="select"
          value={task.type}
          onChange={(e) => setTask({ ...task, type: e.target.value })}
        >
          <option value="standaard">Standaard</option>
          <option value="fysieke afspraak">Fysieke afspraak</option>
          <option value="bel afspraak">Bel afspraak</option>
        </Input>
        <Label className="mt-2">Titel</Label>
        <Input
          type="text"
          value={task.title}
          onChange={(e) => setTask({ ...task, title: e.target.value })}
          placeholder="Voer taak titel in..."
        />
        <Label className="mt-2">Toewijzen aan</Label>
        {user.role === "admin" ? <Input
          type="select"
          value={task.agent}
          onChange={(e) => setTask({ ...task, agent: e.target.value })}
        >
          <option value="">Selecteer agent...</option>
          {agents.map((agent) => (
            <option key={agent.id} value={agent.id}>
              {agent.personalInfo?.firstName || "Onbekend"}
            </option>
          ))}
        </Input> : <><Input value={`${agents.filter(a => a.id === user.id)[0]?.personalInfo.firstName || "onbekend"}`} disabled></Input></>}

        <Row>
          <Col>
            <Label className="mt-2">Start tijd</Label>
            <DatePicker
              selected={task.startTime}
              onChange={(date) => setTask({ ...task, startTime: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Starttijd selecteren"
              className="form-control"
            />
          </Col>
          <Col>
            <Label className="mt-2">Eind tijd</Label>
            <DatePicker
              selected={task.endTime}
              onChange={(date) => setTask({ ...task, endTime: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Eindtijd selecteren"
              className="form-control"
            />
          </Col>
        </Row>
        <Label className="mt-2">Voeg een lead of offerte toe</Label>
        {lead && <><Input value={`${lead.customerDetails.firstName} ${lead.customerDetails.lastName} | ${lead.customerDetails.straat} ${lead.customerDetails.huisnummer} ${lead.customerDetails.plaats}`} disabled></Input>
        </>}
        {!lead && <>
          <Input
            type="select"
            value={task.agent}
            onChange={(e) => setTask({ ...task, agent: e.target.value })}
          >
            <option>Maak een keuze</option>

            {leads.map((lead) => (
              <option key={lead.id} value={lead.id}>
                {lead.customerDetails.firstName} {lead.customerDetails.lastName} | {lead.customerDetails.straat} {lead.customerDetails.huisnummer} {lead.customerDetails.plaats}
              </option>
            ))}
            {offertes.map((offerte) => (
              <option key={offerte.id} value={offerte.id}>
                {offerte.customerDetails?.firstName} {offerte.customerDetails.lastName} | {offerte.customerDetails.straat} {offerte.customerDetails.huisnummer} {offerte.customerDetails.plaats}
              </option>
            ))}

          </Input>
        </>}
        <Label className="mt-2">Omschrijving</Label>
        <Input
          type="textarea"
          value={task.description}
          onChange={(e) => setTask({ ...task, description: e.target.value })}
          placeholder="Geef een omschrijving van de taak..."
        />
        <Label className="mt-2">Opmerkingen</Label>
        <Input
          type="textarea"
          value={task.comments}
          onChange={(e) => setTask({ ...task, comments: e.target.value })}
          placeholder="Voeg opmerkingen toe..."
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Opslaan
        </Button>
        <Button color="secondary" onClick={toggle}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TaskModal;
