import { useLocation } from "react-router-dom";

const usePageIdentifier = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path.startsWith("/admin/dashboard")) return "Dashboard";
  if (path.startsWith("/admin/offertes")) return "Offertes";
  if (path.startsWith("/admin/producten")) return "Producten";
  if (path.startsWith("/admin/pakketten")) return "Pakketten";
  if (path.startsWith("/admin/users")) return "Users";
  if (path.startsWith("/taken")) return "Taken";
  if (path.startsWith("/leads")) return "Leads";
  if (path.startsWith("/agenda")) return "Agenda";
  if (path.startsWith("/offertes")) return "Offertes";
  if (path.startsWith("/sales-tools")) return "Sales-tools";

  return "Admin";
};

export default usePageIdentifier;
