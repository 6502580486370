import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db } from "../firebaseConfig"; // Import Firebase Auth and Firestore
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

// Create User Context
const UserContext = createContext();

// Provider Component
export const UserProvider = ({ children }) => {
    const [user, setUser] = useState(null); // Store user data
    const [loading, setLoading] = useState(true); // Loading state
    const [usersCache, setUsersCache] = useState({}); // Cache for user data

    useEffect(() => {
        // Listen for auth state changes
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
            if (currentUser) {
                const uid = currentUser.uid;
                try {
                    const userDocRef = doc(db, "users", uid);
                    const userDocSnap = await getDoc(userDocRef);

                    if (userDocSnap.exists()) {
                        setUser({ uid, ...userDocSnap.data() });
                    } else {
                        console.error("No such user in Firestore");
                    }
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            } else {
                setUser(null);
            }
            setLoading(false);
        });

        return () => unsubscribe();
    }, []);

    // Function to fetch a user by ID
    const getUserById = async (userId) => {
        if (usersCache[userId]) {
            return usersCache[userId];
        }

        try {
            const userDocRef = doc(db, "users", userId);
            const userDocSnap = await getDoc(userDocRef);

            if (userDocSnap.exists()) {
                const userData = { id: userId, ...userDocSnap.data() };
                setUsersCache((prevCache) => ({ ...prevCache, [userId]: userData }));
                return userData;
            } else {
                console.error("No such user in Firestore");
                return null;
            }
        } catch (error) {
            console.error("Error fetching user data:", error);
            return null;
        }
    };

    // Logout function
    const logout = async () => {
        try {
            await signOut(auth);
            setUser(null);
        } catch (error) {
            console.error("Error during logout:", error);
        }
    };

    return (
        <UserContext.Provider value={{ user, loading, logout, getUserById }}>
            {children}
        </UserContext.Provider>
    );
};

// Custom hook for accessing UserContext
export const useUser = () => {
    return useContext(UserContext);
};
