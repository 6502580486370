import React, { useEffect, useState } from "react";
import { db, storage } from "../../firebaseConfig"; // Firebase Firestore and Storage
import { collection, getDocs, doc, setDoc, getDoc } from "firebase/firestore";
import {
  ref,
  uploadBytes,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
  Collapse,
} from "reactstrap";
import { useParams, useNavigate } from "react-router-dom";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import BackButton from "../../components/BackButton";

const PackageEditPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [packageData, setPackageData] = useState({
    naam: "",
    beschrijving: "",
    prijs: "",
    producten: [],
    images: [],
    datasheets: [],
    active: false,
  });
  const [producten, setProducten] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [datasheetFiles, setDatasheetFiles] = useState([]);
  const [collapsedCategories, setCollapsedCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProductenEnPakket = async () => {
      try {
        const productSnapshot = await getDocs(collection(db, "productsNew"));
        const fetchedProducten = productSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducten(fetchedProducten);

        if (id) {
          const packageDoc = await getDoc(doc(db, "packages", id));
          if (packageDoc.exists()) {
            setPackageData(packageDoc.data());
          }
        }
      } catch (err) {
        console.error("Error bij ophalen van gegevens:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProductenEnPakket();
  }, [id]);

  const handleProductToggle = (productId, type) => {
    setPackageData((prev) => {
      const existingProduct = prev.producten.find(
        (p) => p.productId === productId
      );
      if (existingProduct) {
        return {
          ...prev,
          producten: prev.producten.filter((p) => p.productId !== productId),
        };
      }
      return {
        ...prev,
        producten: [
          ...prev.producten,
          {
            productId,
            naam: producten.find((p) => p.id === productId).name,
            type,
          },
        ],
      };
    });
  };

  const handleFileUpload = async (files, folder) => {
    const uploadPromises = files.map((file) => {
      const fileRef = ref(storage, `${folder}/${file.name}-${Date.now()}`);
      return uploadBytes(fileRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handleSave = async () => {
    try {
      if (!packageData.naam || !packageData.prijs) {
        alert("Vul alle verplichte velden in.");
        return;
      }

      // Upload images and datasheets if provided
      const uploadedImages = await handleFileUpload(
        imageFiles,
        "package-images"
      );
      const uploadedDatasheets = await handleFileUpload(
        datasheetFiles,
        "package-datasheets"
      );

      const packageRef = id
        ? doc(db, "packages", id)
        : doc(collection(db, "packages"));
      await setDoc(packageRef, {
        ...packageData,
        images: [...packageData.images, ...uploadedImages],
        datasheets: [...packageData.datasheets, ...uploadedDatasheets],
      });

      alert("Pakket succesvol opgeslagen!");
      navigate("/admin/pakketten");
    } catch (err) {
      console.error("Fout bij het opslaan van het pakket:", err);
      alert("Opslaan mislukt.");
    }
  };

  const toggleCategory = (category) => {
    setCollapsedCategories((prev) =>
      prev.includes(category)
        ? prev.filter((c) => c !== category)
        : [...prev, category]
    );
  };
  const groupedProducts = producten.reduce((groups, product) => {
    const category = product.category || "Overig"; // Default category if none is defined
    if (!groups[category]) groups[category] = []; // Initialize the category array
    groups[category].push(product); // Add the product to the category
    return groups;
  }, {});

  const handleActiveToggle = () => {
    setPackageData((prev) => ({ ...prev, active: !prev.active }));
  };

  if (loading) return <div>Bezig met laden...</div>;

  return (
    <Container className="package-edit-page ">
      <div className="buttons-block">
        <BackButton />
        <div className="save-button">
          <Button color="success" onClick={handleSave}>
            Opslaan
          </Button>
        </div>
      </div>
      <Card className="p-4 shadow-sm mt-3">
        <CardBody>
          <h2 className="text-center mb-4">
            {id ? "Pakket Bewerken" : "Nieuw Pakket Aanmaken"}
          </h2>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={packageData.active}
                onChange={handleActiveToggle}
              />
              Actief
            </Label>
          </FormGroup>
          <Form>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="naam">Pakket Naam</Label>
                  <Input
                    type="text"
                    id="naam"
                    value={packageData.naam}
                    onChange={(e) =>
                      setPackageData({ ...packageData, naam: e.target.value })
                    }
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="prijs">Prijs (€)</Label>
                  <Input
                    type="number"
                    id="prijs"
                    min={0}
                    value={packageData.prijs}
                    onChange={(e) =>
                      setPackageData({ ...packageData, prijs: e.target.value })
                    }
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <FormGroup>
              <Label for="beschrijving">Beschrijving</Label>
              <ReactQuill
                value={packageData.beschrijving}
                onChange={(value) =>
                  setPackageData({ ...packageData, beschrijving: value })
                }
                theme="snow"
              />
            </FormGroup>

            <h5>Producten</h5>
            {Object.entries(groupedProducts).map(([category, products]) => (
              <div key={category} className="category-group mb-3">
                <Button
                  color="link"
                  className="category-toggle"
                  onClick={() => toggleCategory(category)}
                >
                  {category} ({products.length})
                </Button>
                <Collapse isOpen={!collapsedCategories.includes(category)}>
                  <ListGroup>
                    {products.map((product) => (
                      <ListGroupItem
                        key={product.id}
                        className="d-flex justify-content-between align-items-center"
                      >
                        <div>
                          <strong>{product.name}</strong> - {product.category}
                        </div>
                        <Button
                          size="sm"
                          color={
                            packageData.producten.some(
                              (p) => p.productId === product.id
                            )
                              ? "danger"
                              : "success"
                          }
                          onClick={() =>
                            handleProductToggle(
                              product.id,
                              packageData.producten.some(
                                (p) => p.productId === product.id
                              )
                                ? null
                                : "required"
                            )
                          }
                        >
                          {packageData.producten.some(
                            (p) => p.productId === product.id
                          )
                            ? "Verwijder"
                            : "Toevoegen"}
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Collapse>
              </div>
            ))}
            <Row>
              <Col>
                <h5 className="mt-4">Afbeeldingen</h5>
                <Input
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={(e) => setImageFiles(Array.from(e.target.files))}
                />
                <ListGroup className="mt-3">
                  {packageData.images.map((url, index) => (
                    <ListGroupItem
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        Afbeelding {index + 1}
                      </a>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() =>
                          setPackageData((prev) => ({
                            ...prev,
                            images: prev.images.filter((_, i) => i !== index),
                          }))
                        }
                      >
                        Verwijderen
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
              <Col>
                <h5 className="mt-4">Datasheets</h5>
                <Input
                  type="file"
                  accept=".pdf"
                  multiple
                  onChange={(e) =>
                    setDatasheetFiles(Array.from(e.target.files))
                  }
                />
                <ListGroup className="mt-3">
                  {packageData.datasheets.map((url, index) => (
                    <ListGroupItem
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <a href={url} target="_blank" rel="noopener noreferrer">
                        Datasheet {index + 1}
                      </a>
                      <Button
                        size="sm"
                        color="danger"
                        onClick={() =>
                          setPackageData((prev) => ({
                            ...prev,
                            datasheets: prev.datasheets.filter(
                              (_, i) => i !== index
                            ),
                          }))
                        }
                      >
                        Verwijderen
                      </Button>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </Col>
            </Row>

            <div className="text-center mt-4">
              <Button color="success" onClick={handleSave}>
                Opslaan
              </Button>
              <Button
                color="secondary"
                onClick={() => navigate("/packages")}
                className="ms-3"
              >
                Annuleren
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default PackageEditPage;
