import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Button } from "reactstrap";
import moment from "moment";
import { useUser } from "../../context/UserContext";

const AdminOffertesPage = () => {
    const [offertes, setOffertes] = useState([]);
    const [filteredOffertes, setFilteredOffertes] = useState([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [minPrice, setMinPrice] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [loading, setLoading] = useState(true);
    const { getUserById } = useUser(); // Access the getUserById function

    useEffect(() => {
        const fetchOffertes = async () => {
            try {
                const offertesRef = collection(db, "offertes");
                const q = query(offertesRef, orderBy("createdAt", "desc"));
                const querySnapshot = await getDocs(q);
                const fetchedOffertes = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetch agent names for each offerte
                const updatedOffertes = await Promise.all(
                    fetchedOffertes.map(async (offerte) => {
                        if (offerte.agent) {
                            const agent = await getUserById(offerte.agent);
                            return {
                                ...offerte,
                                agentName: agent?.personalInfo.firstName || "Onbekend",
                            };
                        }
                        return { ...offerte, agentName: "Geen agent" };
                    })
                );

                setOffertes(updatedOffertes);
                setFilteredOffertes(updatedOffertes);
            } catch (error) {
                console.error("Error fetching offertes:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchOffertes();
    }, [getUserById]);

    useEffect(() => {
        let filtered = offertes;

        if (searchQuery) {
            filtered = filtered.filter((offerte) =>
                offerte.customerDetails?.firstName
                    .toLowerCase()
                    .includes(searchQuery.toLowerCase()) || (offerte.agentName?.toLowerCase()?.includes(searchQuery))
            );
        }

        if (minPrice) {
            filtered = filtered.filter(
                (offerte) =>
                    parseFloat(offerte.finalPrice || 0) >= parseFloat(minPrice)
            );
        }

        if (maxPrice) {
            filtered = filtered.filter(
                (offerte) =>
                    parseFloat(offerte.finalPrice || 0) <= parseFloat(maxPrice)
            );
        }

        setFilteredOffertes(filtered);
    }, [searchQuery, minPrice, maxPrice, offertes]);

    return (

        <div className="widget offerte-widget">
            <div className="widget-header">
                <h3>Offertes Overzicht</h3>
                <Button
                    color="primary"
                    onClick={() =>
                        (window.location.href = "/admin/offertes/add")
                    }
                >
                    Voeg Nieuwe Offerte Toe
                </Button>
            </div>
            <div className="widget-content">
                <div className="filters">
                    <input
                        type="text"
                        placeholder="Zoek op klantnaam..."
                        value={searchQuery}
                        onChange={(e) =>
                            setSearchQuery(e.target.value)
                        }
                    />
                    <input
                        type="number"
                        placeholder="Min. prijs (€)"
                        value={minPrice}
                        onChange={(e) => setMinPrice(e.target.value)}
                    />
                    <input
                        type="number"
                        placeholder="Max. prijs (€)"
                        value={maxPrice}
                        onChange={(e) => setMaxPrice(e.target.value)}
                    />
                </div>

                {loading ? (
                    <div>Laden...</div>
                ) : (
                    <table className="admin-table">
                        <thead>
                            <tr>
                                <th>Status</th>
                                <th>Klant / Pakket</th>
                                <th>Agent</th>
                                <th>Datum</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredOffertes.length > 0 ? (
                                filteredOffertes.map((offerte) => (
                                    <tr
                                        key={offerte.id}
                                        onClick={() =>
                                            (window.location.href = `/admin/offertes/${offerte.id}`)
                                        }
                                        className="clickable-row"
                                    >
                                        <td>{offerte.status || "Onbekend"}</td>
                                        <td>
                                            <strong>
                                                {offerte.customerDetails?.firstName ||
                                                    "Onbekend"}
                                            </strong>
                                            <br />
                                            <small>
                                                {offerte.selectedProduct?.naam ||
                                                    "Geen pakket"}
                                            </small>
                                        </td>
                                        <td>{offerte.agentName}</td>
                                        <td>
                                            {moment(
                                                offerte.createdAt?.toDate()
                                            ).format("DD-MM-YYYY")}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="no-data">
                                        Geen offertes beschikbaar.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                )}
            </div>
        </div>

    );
};

export default AdminOffertesPage;
