import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { useUser } from "../context/UserContext";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./Taken.css";
import moment from "moment";
import TaskModal from "../components/AddTaskModal";

const TakenPage = () => {
  const { user } = useUser(); // Get current user from context
  const [tasks, setTasks] = useState([]);
  const [agents, setAgents] = useState([]);
  const [offertes, setOffertes] = useState([]);
  const [leads, setLeads] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [finishedTasks, setFinishedTasks] = useState([]);
  const [showFinishedTasks, setShowFinishedTasks] = useState(false);
  const [finishedTaskModalOpen, setFinishedTaskModalOpen] = useState(false);
  const [selectedFinishedTask, setSelectedFinishedTask] = useState(null);

  const [taskModalOpen, setTaskModalOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  // Form state for adding a new task
  const [newTask, setNewTask] = useState({
    type: "", // Task type
    title: "", // Task title
    agent: "", // Assigned agent
    date: null, // Task date
    startTime: null, // Start time
    endTime: null, // End time
    lead: "", // Lead/Relation ID
    description: "", // Task description
    comments: "", // Task comments
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        let tasksQuery;

        if (user?.role === "admin") {
          // Admin sees all tasks
          tasksQuery = collection(db, "tasks");
        } else {
          // Regular users only see their tasks
          tasksQuery = query(
            collection(db, "tasks"),
            where("agent", "==", user?.uid)
          );
        }

        const tasksSnap = await getDocs(tasksQuery);
        const fetchedTasks = tasksSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch agents
        const agentsSnap = await getDocs(collection(db, "users"));
        const fetchedAgents = agentsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setTasks(fetchedTasks);
        setFilteredTasks(fetchedTasks);
        setAgents(fetchedAgents);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchOffertes = async () => {
      try {
        let offertesQuery;

        if (user?.role === "admin") {
          // Admin sees all offertes
          offertesQuery = collection(db, "offertes");
        } else {
          // Non-admin sees only their offertes
          offertesQuery = query(
            collection(db, "offertes"),
            where("agent", "==", user?.uid)
          );
        }

        const offertesSnap = await getDocs(offertesQuery);
        const fetchedOffertes = offertesSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setOffertes(fetchedOffertes);
      } catch (error) {
        console.error("Error fetching offertes:", error);
      }
    };

    fetchOffertes();
  }, [user]);

  useEffect(() => {
    const filtered = tasks.filter((task) => task.status !== "Afgerond");
    const completed = tasks.filter((task) => task.status === "Afgerond");
    setFilteredTasks(filtered);
    setFinishedTasks(completed);
  }, [tasks, searchQuery, agents, offertes]);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleSaveTask = async (task) => {
    console.log(newTask);
    if (!newTask.title || !newTask.lead || !newTask.date) return;

    console.log(newTask);
    try {
      const taskData = {
        ...newTask,
        agent: user?.role === "admin" ? newTask.agent : user?.uid, // Auto-assign agent for non-admin
      };
      const taskRef = await addDoc(collection(db, "tasks"), taskData);
      setTasks([...tasks, { id: taskRef.id, ...taskData }]);
      setNewTask({
        type: "", // Task type
        title: "", // Task title
        agent: "", // Assigned agent
        date: null, // Task date
        startTime: null, // Start time
        endTime: null, // End time
        lead: "", // Lead/Relation ID
        description: "", // Task description
        comments: "", // Task comments
      });
      toggleModal();
    } catch (error) {
      console.error("Error adding task:", error);
    }
  };

  const handleUpdateTaskStatus = async (id, status) => {
    try {
      const taskRef = doc(db, "tasks", id);
      await updateDoc(taskRef, { status });
      setTasks((prev) =>
        prev.map((task) => (task.id === id ? { ...task, status } : task))
      );
    } catch (error) {
      console.error("Error updating task status:", error);
    }
  };

  const handleDeleteTask = async (id) => {
    try {
      const taskRef = doc(db, "tasks", id);
      await deleteDoc(taskRef);
      setTasks((prev) => prev.filter((task) => task.id !== id));
    } catch (error) {
      console.error("Error deleting task:", error);
    }
  };

  const openTaskModal = (task) => {
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  const toggleTaskModal = () => {
    setTaskModalOpen(!taskModalOpen);
  };

  const handleFinishTask = async (taskId, notes) => {
    if (!notes || notes.trim() === "") {
      alert("Opmerkingen zijn verplicht om de taak te voltooien.");
      return;
    }

    try {
      const taskRef = doc(db, "tasks", taskId);
      await updateDoc(taskRef, {
        status: "Afgerond",
        notes: notes, // Save the notes in Firestore
        finishedAt: new Date(), // Record the completion time
      });

      alert("Taak succesvol afgerond.");
      setTaskModalOpen(false);
      // Refresh tasks here if needed
    } catch (error) {
      console.error("Error finishing task:", error);
      alert("Er is een fout opgetreden bij het voltooien van de taak.");
    }
  };

  const handleEscalateTask = async (taskId) => {
    try {
      const taskRef = doc(db, "tasks", taskId);
      await updateDoc(taskRef, { status: "Escalated" });

      alert("Taak is succesvol geëscaleerd.");
      setTaskModalOpen(false);
      // Refresh tasks here if needed
    } catch (error) {
      console.error("Error escalating task:", error);
      alert("Er is een fout opgetreden bij het escaleren van de taak.");
    }
  };

  if (loading) return <div>Laden...</div>;

  return (
    <Container>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>Taken Beheer</h3>
        <Button color="primary" onClick={toggleModal}>
          Nieuwe Taak
        </Button>
      </div>

      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Input
                type="text"
                placeholder="Zoek naar taken..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="mb-3"
              />
              <Table bordered>
                <thead>
                  <tr>
                    <th>Type</th>
                    <th>Taak</th>
                    <th>Adviseur</th>
                    <th>Offerte</th>
                    <th>Deadline</th>
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredTasks.length > 0 ? (
                    filteredTasks.map((task) => {
                      let deadline = null;
                      if (task.startTime)
                        deadline = moment(task.startTime.toDate()).format(
                          "LLL"
                        );
                      return (
                        <tr key={task.id}>
                          <td>{task.type}</td>
                          <td>{task.title}</td>
                          <td>
                            {agents.find((agent) => agent.id === task.agent)
                              ?.personalInfo?.firstName || "Onbekend"}
                          </td>
                          <td>
                            {offertes.find(
                              (offerte) => offerte.id === task.lead
                            )?.customerDetails?.firstName || "Onbekend"}
                          </td>
                          <td>{deadline}</td>
                          <td>
                            <Button
                              color="success"
                              size="sm"
                              onClick={() => openTaskModal(task)}
                            >
                              Openen
                            </Button>
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Geen actieve taken gevonden.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {/* Finished Tasks Card */}
      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <div className="d-flex justify-content-between align-items-center">
                <h5>Afgeronde Taken</h5>
                <Button
                  color="secondary"
                  size="sm"
                  onClick={() => setShowFinishedTasks(!showFinishedTasks)}
                >
                  {showFinishedTasks ? "Verbergen" : "Tonen"}
                </Button>
              </div>
              {showFinishedTasks && (
                <>
                  {finishedTasks.length > 0 ? (
                    <Table bordered>
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Taak</th>
                          <th>Adviseur</th>
                          <th>Offerte</th>
                          <th>Voltooid op</th>
                        </tr>
                      </thead>
                      <tbody>
                        {finishedTasks.map((task) => {
                          const finishedAt = moment(
                            task.finishedAt.toDate()
                          ).format("LLL");
                          return (
                            <tr
                              onClick={() => {
                                setSelectedFinishedTask(task);
                                setFinishedTaskModalOpen(true);
                              }}
                              key={task.id}
                              style={{ cursor: "pointer" }}
                            >
                              <td>{task.type}</td>
                              <td>{task.title}</td>
                              <td>
                                {agents.find((agent) => agent.id === task.agent)
                                  ?.personalInfo?.firstName || "Onbekend"}
                              </td>
                              <td>
                                {offertes.find(
                                  (offerte) => offerte.id === task.lead
                                )?.customerDetails?.firstName || "Onbekend"}
                              </td>
                              <td>{finishedAt}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  ) : (
                    <p className="text-center">
                      Geen afgeronde taken gevonden.
                    </p>
                  )}
                </>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <TaskModal
        isOpen={modalOpen}
        toggle={toggleModal}
        onSave={handleSaveTask}
        agents={agents}
        initialData={selectedTask}
      />

      <Modal
        className="custom-modal"
        isOpen={taskModalOpen}
        toggle={toggleTaskModal}
      >
        <ModalHeader toggle={toggleTaskModal}>Taak Details</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label>Type</Label>
              <Input type="text" value={selectedTask?.type || ""} disabled />
            </Col>
            <Col>
              <Label className="mt-2">Titel</Label>
              <Input type="text" value={selectedTask?.title || ""} disabled />
            </Col>
          </Row>

          <Label className="mt-2">Toegewezen aan</Label>
          <Input
            type="text"
            value={
              agents.find((agent) => agent.id === selectedTask?.agent)
                ?.personalInfo?.firstName || "Onbekend"
            }
            disabled
          />

          <Label className="mt-2">Lead/Relatie</Label>
          <Input
            type="text"
            value={
              offertes.find((offerte) => offerte.id === selectedTask?.lead)
                ?.customerDetails?.firstName || "Onbekend"
            }
            disabled
          />

          <Label className="mt-2">Deadline</Label>
          <Input
            type="text"
            value={moment(selectedTask?.startTime.toDate()).format("LLL")}
            disabled
          />

          <Label className="mt-2">Omschrijving</Label>
          <Input
            type="textarea"
            value={selectedTask?.description || ""}
            disabled
          />

          <Label className="mt-2">Opmerkingen</Label>
          <Input
            type="textarea"
            value={selectedTask?.notes || ""}
            onChange={(e) =>
              setSelectedTask({ ...selectedTask, notes: e.target.value })
            }
            placeholder="Voer opmerkingen of resultaten in..."
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={() =>
              handleFinishTask(selectedTask?.id, selectedTask?.notes)
            }
          >
            Taak Voltooien
          </Button>
          {/* {user?.role === "admin" && (
            <Button
              color="warning"
              onClick={() => handleEscalateTask(selectedTask?.id)}
            >
              Escaleren
            </Button>
          )} */}
          <Button color="secondary" onClick={toggleTaskModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={finishedTaskModalOpen}
        toggle={() => setFinishedTaskModalOpen(!finishedTaskModalOpen)}
      >
        <ModalHeader toggle={() => setFinishedTaskModalOpen(false)}>
          Taak Details
        </ModalHeader>
        <ModalBody>
          {selectedFinishedTask ? (
            <>
              <Row>
                <Col>
                  <Label>Type</Label>
                  <Input
                    type="text"
                    value={selectedFinishedTask.type}
                    disabled
                  />
                </Col>
                <Col>
                  <Label className="mt-2">Titel</Label>
                  <Input
                    type="text"
                    value={selectedFinishedTask.title}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="mt-2">Toegewezen aan</Label>
                  <Input
                    type="text"
                    value={
                      agents.find(
                        (agent) => agent.id === selectedFinishedTask.agent
                      )?.personalInfo?.firstName || "Onbekend"
                    }
                    disabled
                  />
                </Col>
                <Col>
                  {" "}
                  <Label className="mt-2">Lead/Relatie</Label>
                  <Input
                    type="text"
                    value={
                      offertes.find(
                        (offerte) => offerte.id === selectedFinishedTask.lead
                      )?.customerDetails?.firstName || "Onbekend"
                    }
                    disabled
                  />
                </Col>
              </Row>

              <Label className="mt-2">Voltooid op</Label>
              <Input
                type="text"
                value={moment(selectedFinishedTask.finishedAt.toDate()).format(
                  "LLL"
                )}
                disabled
              />

              <Label className="mt-2">Omschrijving</Label>
              <Input
                type="textarea"
                value={selectedFinishedTask.description || "Geen omschrijving"}
                disabled
              />

              <Label className="mt-2">Opmerkingen</Label>
              <Input
                type="textarea"
                value={selectedFinishedTask.notes || "Geen opmerkingen"}
                disabled
              />
            </>
          ) : (
            <p>Geen taak geselecteerd.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setFinishedTaskModalOpen(false)}
          >
            Sluiten
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default TakenPage;
