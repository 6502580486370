import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db, updateOfferteStatus } from "../firebaseConfig";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import PdfGenerator from "../components/PdfGenerator";
import PdfViewerModal from "../components/PdfViewerModal";
import jsPDF from "jspdf";
import "jspdf-autotable";
import Offerte1 from "../templates/offertes/Offerte1";
import {
  generateOfferte,
  sendEmail,
  sendEmailWithAttachment,
  sendQuoteEmail,
  toastHelper,
} from "../utils/helpers";

const SingleOffertePage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [offerte, setOfferte] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);

  const toggleModal = () => setModalOpen(!isModalOpen);
  useEffect(() => {
    const fetchOfferte = async () => {
      const docRef = doc(db, "offertes", id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        let data = docSnap.data();
        data.id = docRef.id;
        setOfferte(data);
      } else {
        console.error("No such document!");
      }
    };

    fetchOfferte();
  }, [id]);

  if (!offerte) {
    return <div>Loading offerte...</div>;
  }

  const sendOfferte = async () => {
    offerte.id = id;
    try {
      let res = await sendQuoteEmail(offerte);
      if (res) {
        await updateOfferteStatus(offerte.id, "Verzonden", {
          sentAt: new Date(),
        });

        console.log("Email sent successfully!");
        toastHelper.success("Offerte met succes naar de klant gemaild!");
      } else {
        toastHelper.error("Er ging iets fout. Probeer het later nog eens.");
      }
    } catch (error) {
      console.log(`Error: ${error.message}`);
    }
  };

  const sendOfferteToClient = async (data) => {
    // console.log(data);
    // return;
    try {
      const res = await sendQuoteEmail(data);
      if (res) {
        console.log(res);
        // await updateOfferteStatus(data.id, "Verzonden");
        // navigate(`/offertes/${docRef.id}`);
        toastHelper.success("Offerte is succesvol verstuurd!");
        navigate(`/offertes/${id}`);
      } else {
        await updateOfferteStatus(id, "Verzenden mislukt");
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
      }

      // Optional: You can trigger an email API here
      // Example: Call an external API or email service
      // await sendEmail(data.customerDetails.email, docRef.id, data);
    } catch (error) {
      console.error("Error sending offerte to client: ", error);
      if (id) {
        await updateOfferteStatus(id, "Verzenden mislukt", {
          errorMessage: error.message,
        });
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
      }
    }
  };

  // console.log(offerte);

  return (
    <div className="container">
      <h2>Offerte Details</h2>
      <Row>
        <Col md={8}>
          <Card className="mb-4 info-block">
            <div className={`status ${offerte.status}`}>
              <strong>Status: </strong> &nbsp; {offerte.status}
            </div>
            <CardBody>
              <h5>Offerte #{offerte.offerteNr}</h5>
              <Row>
                <Col>
                  <p>
                    <strong>Contactgegevens:</strong> <br />
                    <strong>Naam:</strong> {offerte.customerDetails?.firstName}{" "}
                    {offerte.customerDetails?.lastName}
                    <br />
                    <strong>Email:</strong> {offerte.customerDetails?.email}
                    <br />
                    <strong>Telefoon:</strong> {offerte.customerDetails?.phone}
                    <br />
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>Adres:</strong> <br />
                    {offerte.customerDetails?.straat}
                    {offerte.customerDetails?.huisnummer} <br />
                    {offerte.customerDetails?.postcode}{" "}
                    {offerte.customerDetails?.plaats}
                    <br />
                    {offerte.customerDetails?.provincie}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>
          <Card className="p-4 shadow-sm">
            <h3>
              <strong>Het product</strong>
            </h3>
            <div>
              <Row>
                <Col></Col>
              </Row>
              <Row style={{ marginBottom: 40, marginTop: 20 }}>
                <Col>
                  <img
                    src={offerte.selectedProduct.images[0]}
                    alt={offerte.selectedProduct.naam}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Col>
                <Col xs={6}>
                  <h5 style={{ margin: "20px 0" }}>
                    {offerte.selectedProduct.naam}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: offerte.selectedProduct.beschrijving,
                    }}
                  ></p>
                  <div className="separator price"></div>
                  <h5>Meerwerk</h5>
                  {offerte.selectedExtras.length > 0 && (
                    <ul>
                      {offerte.selectedExtras.map((a, i) => {
                        // console.log(a);
                        return (
                          <li key={i}>
                            {a.name}:{" "}
                            {a.type === "quantity" && <>X {a.quantity}</>}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <CardBody>
              <h5>Actiess</h5>
              <Button
                block={true}
                // onClick={generatePdf}
                onClick={() =>
                  (window.location.href = `/public/offerte/${id}/${offerte.passcode}`)
                }
                color="primary"
                className="mb-2"
              >
                Offerte bekijken
              </Button>
              <Button
                block={true}
                onClick={() => (window.location.href = `/offertes/edit/${id}`)}
                color="warning"
                className="mb-2"
              >
                Offerte bewerken
              </Button>

              <Button
                block={true}
                color="secondary"
                className="mb-2"
                onClick={() => sendOfferteToClient(offerte)}
              >
                Verzend naar Klant
              </Button>
              <Button block={true} color="danger">
                Annuleer Offerte
              </Button>
            </CardBody>
          </Card>
          <Card className="mb-4">
            <CardBody>
              <h5>Prijs</h5>
              <p className="pricing">
                Prijs (excl. BTW):{" "}
                <span>€{offerte.priceDetails?.basePrice}</span>
              </p>
              {offerte.priceDetails.basePrice -
                offerte.priceDetails.discountedPrice >
                0 && (
                <>
                  <p className="pricing discount">
                    - Korting:{" "}
                    <span>
                      - €
                      {(
                        offerte.priceDetails.basePrice -
                        offerte.priceDetails.discountedPrice
                      ).toLocaleString()}
                    </span>
                  </p>
                  <p className="pricing">
                    Prijs na korting (excl. BTW):{" "}
                    <span>
                      €{offerte.priceDetails.discountedPrice.toLocaleString()}
                    </span>
                  </p>
                </>
              )}

              <div className="separator price"></div>

              <p className="pricing">
                BTW (21%): <span>€{offerte.priceDetails.VAT}</span>
              </p>
              <div className="separator price"></div>

              <p className="pricing total-price">
                <strong>Totaal (incl. BTW):</strong>{" "}
                <span>€{offerte.priceDetails.totalIncludingVAT}</span>
              </p>
            </CardBody>
          </Card>
          <Card>
            <CardBody>
              <h5>Datasheets</h5>
              {offerte.selectedProduct.datasheets?.map((extra, index) => (
                <div key={index}>
                  <a href={extra} target="_blank" rel="noopener noreferrer">
                    Thuisbatterij Datasheet
                  </a>
                </div>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <PdfViewerModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        pdfBlob={pdfBlob}
      />
    </div>
  );
};

export default SingleOffertePage;
