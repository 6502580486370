import React from "react";
import { Link } from "react-router-dom";
import "./StartPage.css";
import { FiTool, FiUserCheck } from "react-icons/fi";
import logo from "../Assets/logo.webp"; // Vervang door jullie eigen logo.
import { HiOutlineDocumentMagnifyingGlass } from "react-icons/hi2";

const StartPage = () => {
  return (
    <div className="start-page">
      <h3 style={{ fontWeight: "Bold", marginBottom: 30 }}>Maak een keuze om verder te gaan</h3>

      <div className="options">
        <div className="option">
          <Link to="/offerte">
            <div className="icon">
              <FiTool size={40} />
            </div>
            <h2>Offerte</h2>
            <p>Stel een offerte op</p>
          </Link>
        </div>

        {/* <div className="option">
          <Link to="/">
            <div className="icon">
              <FiUserCheck size={40} />
            </div>
            <h2>Lead</h2>
            <p>Voer klantinformatie in</p>
          </Link>
        </div>

        <div className="option">
          <Link to="/">
            <div className="icon">
              <HiOutlineDocumentMagnifyingGlass size={40} />
            </div>
            <h2>Adviesrapport</h2>
            <p>Maak en verstuur een adviesrapport</p>
          </Link>
        </div> */}
      </div>
    </div>
  );
};

export default StartPage;
