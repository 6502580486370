import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import {
  addDoc,
  collection,
  doc,
  getDocs,
  updateDoc,
} from "firebase/firestore";
import { Link, useNavigate } from "react-router-dom";
import "./ProductsPage.css";
import {
  Button,
  Table,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import * as XLSX from "xlsx";

const ProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "productsNew"));
        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setProducts(fetchedProducts);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching products:", err);
        setError("Failed to load products. Please try again.");
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  const toggleModal = (product = null) => {
    setSelectedProduct(product);
    setIsModalOpen(!isModalOpen);
  };

  const exportProducts = () => {
    const ws = XLSX.utils.json_to_sheet(products);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Products");
    XLSX.writeFile(wb, "products.xlsx");
  };

  const downloadTemplate = () => {
    const templateData = [
      { name: "Product Name", category: "Category", price: "Price" },
    ]; // Template row with headers
    const ws = XLSX.utils.json_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    XLSX.writeFile(wb, "product_template.xlsx");
  };

  const importProducts = async (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = async (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const importedProducts = XLSX.utils.sheet_to_json(worksheet);

        for (const product of importedProducts) {
          // Ensure the product object has required fields
          if (!product.name || !product.category) {
            console.error(
              "Invalid product entry: missing required fields",
              product
            );
            continue; // Skip invalid rows
          }

          const productRef = collection(db, "productsNew"); // Collection reference
          await addDoc(productRef, {
            name: product.name,
            category: product.category,
            price: product.price || 0, // Default to 0 if price is missing
            createdAt: new Date(),
          });
        }

        alert("Products imported successfully!");
        window.location.reload(); // Refresh the page to fetch the new products
      } catch (err) {
        console.error("Error importing products:", err);
        alert("Failed to import products.");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  if (loading) return <div>Loading products...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <Container className="products-page mt-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2>Producten</h2>
        <div>
          {/* <Button color="secondary" onClick={downloadTemplate} className="me-2">
            Download Template
          </Button>
          <Button color="success" onClick={exportProducts} className="me-2">
            Exporteren
          </Button> */}
          {/* <Input
            type="file"
            accept=".xlsx, .csv"
            onChange={importProducts}
            style={{ display: "inline-block", width: "auto" }}
          /> */}
          <Button
            color="primary"
            onClick={() => navigate("/add/product")}
            className="ms-2"
          >
            Voeg Nieuw Product Toe
          </Button>
        </div>
      </div>
      {products.length === 0 ? (
        <p>Geen producten beschikbaar.</p>
      ) : (
        <Table responsive striped hover>
          <thead>
            <tr>
              <th>Afbeelding</th>
              <th>Naam</th>
              <th>Categorie</th>
              <th>Prijs</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr key={product.id}>
                <td>
                  {product.images.length > 0 && (
                    <img
                      src={product.images && product.images[0]}
                      alt={product.name}
                      className="table-product-image"
                      style={{ maxWidth: "100px", height: "auto" }}
                    />
                  )}
                </td>
                <td>
                  <b>{product.name}</b>
                  <br />
                  <span
                    dangerouslySetInnerHTML={{ __html: product.specifications }}
                  ></span>
                </td>
                <td>{product.category}</td>
                <td>€{product.price?.toLocaleString() || "N/A"}</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => navigate(`/edit/product/${product.id}`)}
                    className="me-2"
                  >
                    Bewerken
                  </Button>
                  <Button
                    color="info"
                    size="sm"
                    onClick={() => toggleModal(product)}
                  >
                    Bekijk
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
      {selectedProduct && (
        <Modal isOpen={isModalOpen} toggle={() => toggleModal(null)}>
          <ModalHeader toggle={() => toggleModal(null)}>
            {selectedProduct.name}
          </ModalHeader>
          <ModalBody>
            {selectedProduct.images.length > 0 && (
              <img
                src={selectedProduct.images && selectedProduct.images[0]}
                alt={selectedProduct.name}
                style={{ width: "100%", height: "auto", marginBottom: "1rem" }}
              />
            )}

            <p>
              <strong>Categorie:</strong> {selectedProduct.category}
            </p>
            <p>
              <strong>Prijs:</strong> €
              {selectedProduct.price?.toLocaleString() || "N/A"}
            </p>
            <span
              dangerouslySetInnerHTML={{
                __html: selectedProduct.specifications,
              }}
            ></span>
          </ModalBody>
        </Modal>
      )}
    </Container>
  );
};

export default ProductsPage;
