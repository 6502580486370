import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const PackageModal = ({ packageItem, onClose, onSelect }) => {
  return (
    <Modal isOpen={!!packageItem} toggle={onClose}>
      <ModalHeader toggle={onClose}>{packageItem?.naam || "Pakket Details"}</ModalHeader>
      <ModalBody>
        <img
          src={packageItem?.images && packageItem.images[0]}
          alt={packageItem?.naam}
          style={{ width: "100%", height: "auto", marginBottom: "15px", borderRadius: "4px" }}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = "/fallback-image.png"; // Path to fallback image
          }}
        />
        <h5>Beschrijving</h5>
        <div dangerouslySetInnerHTML={{ __html: packageItem?.beschrijving || "Geen beschrijving beschikbaar" }} />
        <h5 className="mt-4">Prijs</h5>
        <p>€{packageItem?.prijs ? packageItem.prijs.toLocaleString() : "N/A"}</p>
        {/* <h5 className="mt-4">Producten in het pakket</h5> */}
        {/* {packageItem?.producten?.length > 0 ? (
          <ul>
            {packageItem.producten.map((product, index) => (
              <li key={index}>{product.naam}</li>
            ))}
          </ul>
        ) : (
          <p>Geen producten in dit pakket.</p>
        )} */}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSelect}>
          Selecteer Dit Pakket
        </Button>
        <Button color="secondary" onClick={onClose}>
          Sluiten
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PackageModal;
