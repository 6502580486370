/**
 * Get the first letter of the first and last name.
 * @param {string} fullName - The full name of the person.
 * @returns {string} - Initials derived from the first and last name.
 */
function getInitials(fullName) {
  if (!fullName) return "";

  const words = fullName.trim().split(/\s+/); // Split by whitespace
  const firstLetter = words[0]?.charAt(0).toUpperCase(); // First letter of the first word
  const lastLetter = words[words.length - 1]?.charAt(0).toUpperCase(); // First letter of the last word

  return `${firstLetter}${lastLetter}`; // Combine initials
}

export default getInitials;
