import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import { collection, getDocs, doc, addDoc } from "firebase/firestore";
import { Button, Table } from "reactstrap";
import "./Packages.css"; // Optional CSS for styling
import { FiAlignJustify, FiGrid } from "react-icons/fi";

const PackagesPage = () => {
  const [packages, setPackages] = useState([]);
  const [filteredPackages, setFilteredPackages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Filters
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");

  // View mode state
  const [viewMode, setViewMode] = useState("grid");

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "packages"));
        const fetchedPackages = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setPackages(fetchedPackages);
        setFilteredPackages(fetchedPackages); // Initialize filteredPackages with all packages
        setLoading(false);
      } catch (err) {
        console.error("Error fetching packages:", err);
        setError("Failed to load packages. Please try again.");
        setLoading(false);
      }
    };

    fetchPackages();
  }, []);

  // Filter logic
  useEffect(() => {
    let filtered = packages;

    if (searchQuery) {
      filtered = filtered.filter((pkg) =>
        pkg.naam.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    if (minPrice) {
      filtered = filtered.filter((pkg) => parseFloat(pkg.prijs) >= parseFloat(minPrice));
    }

    if (maxPrice) {
      filtered = filtered.filter((pkg) => parseFloat(pkg.prijs) <= parseFloat(maxPrice));
    }

    setFilteredPackages(filtered);
  }, [searchQuery, minPrice, maxPrice, packages]);

  const handleDuplicatePackage = async (packageItem) => {
    try {
      // Duplicate package data
      const newPackageData = {
        ...packageItem,
        naam: `${packageItem.naam} (Kopie)`,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      delete newPackageData.id; // Remove the original ID

      // Save the duplicated package
      const docRef = await addDoc(collection(db, "packages"), newPackageData);

      // Redirect to edit/add package page with the new package ID
      window.location.href = `/edit-package/${docRef.id}`;
    } catch (err) {
      console.error("Error duplicating package:", err);
      alert("Het dupliceren van het pakket is mislukt.");
    }
  };

  const renderGrid = () => (
    <div className="packages-grid">
      {filteredPackages.map((packageItem) => (
        <div className="package-card" key={packageItem.id}>
          {/* {packageItem.images && packageItem.images.length > 0 && (
            <img
              src={packageItem.images[0]}
              alt={packageItem.naam}
              className="package-image"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src = "/fallback-image.png";
              }}
            />
          )} */}
          <h3>{packageItem.naam}</h3>
          <p>
            <strong>Beschrijving:</strong>{" "}
            <span
              dangerouslySetInnerHTML={{
                __html: packageItem.beschrijving || "Geen beschrijving",
              }}
            ></span>
          </p>
          <p>
            <strong>Prijs:</strong> €{packageItem.prijs ? packageItem.prijs.toLocaleString() : "N/A"}
          </p>
          {/* <p>
            <strong>Aantal producten:</strong> {packageItem.products?.length || 0}
          </p> */}
          <Button
            color="info"
            size="sm"
            className="details-button"
            onClick={() => (window.location.href = `/edit-package/${packageItem.id}`)}
          >
            Bewerken
          </Button>
          <Button
            color="secondary"
            size="sm"
            className="duplicate-button ms-2"
            onClick={() => handleDuplicatePackage(packageItem)}
          >
            Dupliceren
          </Button>
        </div>
      ))}
    </div>
  );

  const renderTable = () => (
    <Table bordered striped>
      <thead>
        <tr>
          {/* <th>Afbeelding</th> */}
          <th>Naam</th>
          <th>Beschrijving</th>
          <th>Prijs</th>
          {/* <th>Aantal Producten</th> */}
          <th>Acties</th>
        </tr>
      </thead>
      <tbody>
        {filteredPackages.map((packageItem) => (
          <tr key={packageItem.id}>
            {/* <td>
              {packageItem.images && packageItem.images.length > 0 ? (
                <img
                  src={packageItem.images[0]}
                  alt={packageItem.naam}
                  style={{ width: "50px", height: "50px", objectFit: "cover" }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = "/fallback-image.png";
                  }}
                />
              ) : (
                "Geen afbeelding"
              )}
            </td> */}
            <td>{packageItem.naam}</td>
            <td dangerouslySetInnerHTML={{ __html: packageItem.beschrijving || "Geen beschrijving" }} />
            <td>€{packageItem.prijs ? packageItem.prijs.toLocaleString() : "N/A"}</td>
            {/* <td>{packageItem.products?.length || 0}</td> */}
            <td>
              <Button
                color="info"
                size="sm"
                onClick={() => (window.location.href = `/edit-package/${packageItem.id}`)}
              >
                Bewerken
              </Button>
              <Button
                color="secondary"
                size="sm"
                className="ms-2"
                onClick={() => handleDuplicatePackage(packageItem)}
              >
                Dupliceren
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  if (loading) return <div>Loading packages...</div>;
  if (error) return <div className="error">{error}</div>;

  return (
    <div className="packages-page">
      <div className="header-section">
        <h2>Pakketten Overzicht</h2>
        <Button
          style={{ marginLeft: "auto", marginRight: 20 }}
          color="primary"
          onClick={() => (window.location.href = "/add/package")}
        >
          Voeg Nieuw Pakket Toe
        </Button>

        <div className="view-toggle d-flex justify-content-end">
          <Button
            color={viewMode === "grid" ? "primary" : "secondary"}
            onClick={() => setViewMode("grid")}
            style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
          >
            <FiGrid style={{ fontSize: 25 }} />
          </Button>
          <Button
            style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
            color={viewMode === "table" ? "primary" : "secondary"}
            onClick={() => setViewMode("table")}
          >
            <FiAlignJustify style={{ fontSize: 25 }} />
          </Button>
        </div>
      </div>
      {/* Filters Section */}
      <div className="filters">
        <input
          type="text"
          placeholder="Zoek op naam..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min. prijs (€)"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max. prijs (€)"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
      </div>


      {viewMode === "grid" ? renderGrid() : renderTable()}
    </div>
  );
};

export default PackagesPage;
