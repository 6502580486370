import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, limit, doc, getDoc } from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Button } from "reactstrap";
import { FaLink } from "react-icons/fa";

const OfferteWidget = () => {
    const [offertes, setOffertes] = useState([]);
    const [agents, setAgents] = useState({}); // Store agent names by ID

    useEffect(() => {
        const fetchOffertes = async () => {
            try {
                const offertesRef = collection(db, "offertes");
                const q = query(offertesRef, orderBy("createdAt", "desc"), limit(5));
                const querySnapshot = await getDocs(q);
                const fetchedOffertes = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));

                // Fetch agents for the fetched offertes
                const agentIds = [...new Set(fetchedOffertes.map((offerte) => offerte.agent))];
                const agentData = await fetchAgents(agentIds);

                setAgents(agentData);
                setOffertes(fetchedOffertes);
            } catch (error) {
                console.error("Error fetching offertes:", error);
            }
        };

        fetchOffertes();
    }, []);

    const fetchAgents = async (agentIds) => {
        const agentData = {};
        for (const id of agentIds) {
            if (id) {
                try {
                    const agentDocRef = doc(db, "users", id);
                    const agentDocSnap = await getDoc(agentDocRef);
                    if (agentDocSnap.exists()) {
                        agentData[id] = agentDocSnap.data().personalInfo.firstName || "Naam onbekend";
                    }
                } catch (error) {
                    console.error(`Error fetching agent with ID ${id}:`, error);
                }
            }
        }
        return agentData;
    };

    return (
        <div className="widget offerte-widget">
            <div className="widget-header">
                <h3>Nieuwste Offertes</h3>
                <Button
                    color="link"
                    className="link-button"
                    onClick={() => (window.location.href = "/admin/offertes")}
                >
                    <FaLink /> Alle Offertes
                </Button>
            </div>
            <div className="widget-content">
                <table className="offerte-table" style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Status</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Klant / Pakket</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Agent</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offertes.length > 0 ? (
                            offertes.map((offerte) => (
                                <tr key={offerte.id} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ padding: "8px" }}>{offerte.status || "Onbekend"}</td>
                                    <td style={{ padding: "8px" }}>
                                        <strong>{offerte.customerDetails?.firstName || "Onbekend"} {offerte.customerDetails?.lastName || "Onbekend"}</strong>
                                        <br />
                                        <small>{offerte.selectedProduct?.naam || "Geen pakket"}</small>
                                    </td>
                                    <td style={{ padding: "8px" }}>
                                        {agents[offerte.agent] || "Geen agent"}
                                    </td>
                                    <td style={{ padding: "8px" }}>
                                        {offerte.createdAt?.toDate().toLocaleDateString("nl-NL") || "Onbekend"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                                    Geen offertes beschikbaar
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default OfferteWidget;
