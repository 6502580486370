import React, { useState } from "react";

import GraphWidget from "../../components/GraphWidget"
import OfferteWidget from "../../components/OfferteWidget"
function Dashboard() {


  return <>
    <GraphWidget title="Sales" />
    <OfferteWidget title="Sales" />
  </>;
}

export default Dashboard;
