import React from "react";
import { useParams, Link } from "react-router-dom";
import "./SingleProduct.css";
import BackButton from "../components/BackButton";


const SingleProductPage = ({ product, onBack }) => {

  if (!product) return <>
    <BackButton />
    error

  </>
  return (
    <div className="single-product-page">


      <div className="product-container">
        {/* Product Details */}
        <div className="product-details">
          <h1>{product.name}</h1>

          <section>
            <h3>Afmetingen</h3>
            <p>Breedte: {product.dimensions.width}mm</p>
            <p>Diepte: {product.dimensions.depth}mm</p>
            <p>Hoogte: {product.dimensions.height}mm</p>
          </section>

          <section>
            <h3>Toepasbaarheid</h3>
            <p>{product.applicability}</p>
          </section>

          <section>
            <h3>Veiligheid en betrouwbaarheid</h3>
            <ul>
              {product.safety.map((item, index) => (
                <li key={index}>✔ {item}</li>
              ))}
            </ul>
          </section>

          <section>
            <h3>Belangrijkste Kenmerken</h3>
            <ul>
              {product.features.map((feature, index) => (
                <li key={index}>✔ {feature}</li>
              ))}
            </ul>
          </section>

          <section>
            <p>{product.description}</p>
          </section>

          <div className="downloads">
            {product.datasheets.map((sheet, index) => (
              <a
                key={index}
                href={sheet.link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {sheet.name}
              </a>
            ))}
          </div>
        </div>

        {/* Pricing Section */}
        <div className="pricing-section">
          <img
            src={product.image}
            alt={product.name}
            className="product-image"
          />
          <div className="product-price">
            <h2>Eenmalige kosten</h2>
            <h3>€ {product.price.toLocaleString()}</h3>
          </div>
          <button className="cta-button">Offerte</button>
        </div>
      </div>
    </div>
  );
};

export default SingleProductPage;
