import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, limit } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Button } from "reactstrap";
import { FaLink } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const AdminProductsPage = () => {
    const [products, setProducts] = useState([]);
    const navigate = useNavigate()
    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const productsRef = collection(db, "productsNew");
                const q = query(productsRef, orderBy("createdAt", "desc"), limit(10));
                const querySnapshot = await getDocs(q);
                const fetchedProducts = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setProducts(fetchedProducts);
            } catch (error) {
                console.error("Error fetching products:", error);
            }
        };

        fetchProducts();
    }, []);

    const openSingle = (product) => {
        navigate(`/admin/producten/${product.id}`)
    }
    return (
        <div className="widget product-widget">
            <div className="widget-header">
                <h3>Nieuwste Producten</h3>
                <Button
                    color="primary"
                    className="link-button"
                    onClick={() => (window.location.href = "/admin/producten/add")}
                >
                    Product Toevoegen
                </Button>
            </div>
            <div className="widget-content">
                <table className="product-table" style={{ width: "100%", borderCollapse: "collapse" }}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Naam</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Prijs</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Categorie</th>
                            <th style={{ textAlign: "left", padding: "8px", borderBottom: "2px solid #ddd" }}>Datum</th>
                        </tr>
                    </thead>
                    <tbody>
                        {products.length > 0 ? (
                            products.map((product) => (
                                <tr onClick={() => openSingle(product)} key={product.id} style={{ borderBottom: "1px solid #ddd" }}>
                                    <td style={{ padding: "8px" }}>{product.name || "Onbekend"}</td>
                                    <td style={{ padding: "8px" }}>€{product.price?.toLocaleString() || "N/A"}</td>
                                    <td style={{ padding: "8px" }}>{product.category || "Onbekend"}</td>
                                    <td style={{ padding: "8px" }}>
                                        {product.createdAt?.toDate().toLocaleDateString("nl-NL") || "Onbekend"}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                                    Geen producten beschikbaar
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default AdminProductsPage;
